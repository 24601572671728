import React, { Component, useState } from 'react';
import ReactHtmlParser from 'react-html-parser'; 
import axios from 'axios';
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

export default class Home extends Component {
	constructor(props) {
		super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {s3Data: null};
  }

  componentDidMount() {
    if (!this.state.s3Data){
      this.buildMenuItems();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.s3Data && prevState.s3Data.includes('DOCTYPE')){
      this.setState({s3Data:null});
    }
  }

  handleClick = (e) => {
    e.preventDefault();
    this.doNavigation(e.target.attributes.getNamedItem('data').value);
    const href = e.target.attributes.getNamedItem('href').value;
    history.push(href);
  };

  doNavigation = (location) => {
    console.log('doNavigation')
    axios
      .get('//s3.amazonaws.com/fredfoy.com/' + location)
      .then(result => {
      this.setState({s3Data: result.request.response}); //causes a render
    })
    .catch(error => {
      console.log("ERROR " + JSON.stringify(error));
    })
  }

  buildMenuItems = () => {
    var ul = document.createElement('ul');
    //divide map by 2 to determine left and right
    var splitMenu = this.aboutFredFoyMap.length / 2;
    var ulItem = document.getElementById('fredMenul').appendChild(ul);
    let bSplitMenu = false;
    this.aboutFredFoyMap.forEach((element, index) => {
      
      if(index >= splitMenu && !bSplitMenu){
        var ul = document.createElement('ul');
        ulItem = document.getElementById('fredMenur').appendChild(ul);
        bSplitMenu = true;
      }

      let li = document.createElement('li');
      let a = document.createElement("a");
      a.onclick = this.handleClick;
      a.href = element[0];
      a.innerHTML = element[1].display;
      a.setAttribute('data',element[1].data);
      li.append(a);
      ulItem.appendChild(li);
      let hr = document.createElement('hr')
      ulItem.appendChild(hr);
    })
  }
 
	render() {
    const thereIsData = this.state.s3Data ? true : false;
    let showMenu = ''
    let renderHTML = null;
    if (thereIsData) {
      renderHTML = ReactHtmlParser (this.state.s3Data);
      showMenu = 'hideMenuDisplay'
    }
    return ( 
      <div>
        {renderHTML}
        <div className={showMenu}>
          <div id='fredMenu1' >
            <div id='fredMenul' className='menu1l menux'></div>
            <div id='fredMenur' className='menu1r menux'></div>
          </div>
        </div>
      </div> 
    )
  }	

  aboutFredFoyMap = [
    ['/aboutfredfoy/Wabash-Legacy-Display',{'data':'Wabash.html/Wabash.html','display':'2020 - Fred Foy Legacy Display - Wabash County Museum, Mt. Carmel IL'}],
    ['/aboutfredfoy/A-Masked-Man-An-Indian-and-Me',{'data':'A+Masked+Man.html/A+Masked+Man.html','display':'A Masked Man, An Indian and Me'}],
    ["/aboutfredfoy/Betty's-Family-History",{'data':'A+Family+History.html/A+Family+History.html','display':"Betty's Family History"}],
    ['/aboutfredfoy/Vintage-Foy-Family-Pictures',{'data':'Vintage+Family+Photos.html/Vintage+Family+Photos.html','display':'Vintage Foy Family Pictures'}],
    ['/aboutfredfoy/Vintage-Bingham-Family-Pictures',{'data':'Vintage+Bingham+Family+Pictures.html/Vintage+Bingham+Family+Pictures.html','display':'Vintage Bingham Family Pictures'}],
    ['/aboutfredfoy/1942-Cairo-Calling',{'data':'Cairo.html/Cairo.html','display':'1942 Cairo Calling'}],
    ['/aboutfredfoy/1946-Post-War, The "Major" and CBS in Hollywood',{'data':'Post+War.html/Post+War.html','display':'1946 Post War, The "Major" and CBS in Hollywood'}],
    ['/aboutfredfoy/WXYZ',{'data':'WXYZ.html/WXYZ.html','display':'WXYZ'}],
    ['/aboutfredfoy/1946-Intrigue-Script',{'data':'Intrigue.html/Intrigue.html','display':'1946 Intrigue Script'}],
    ['/aboutfredfoy/1947-Fred-and-Fran-Family-Pictorial',{'data':'Fred+and+Fran+Pictorial.html/Fred+and+Fran+Pictorial.html','display':'1947 Fred and Fran Family Pictorial'}],
    ['/aboutfredfoy/1949-AFRA/AFTRA-Annual-Magazines',{'data':'AFTRA.html/AFTRA.html','display':'1949 AFRA/AFTRA Annual Magazines'}],
    ['/aboutfredfoy/1950-Ferdinand-Foy-Retirement',{'data':'Ferdinand+Retirement.html/Ferdinand+Retirement.html','display':'1950 Ferdinand Foy Retirement'}],
    ['/aboutfredfoy/1951-City-of-Freedom-/-250th-Birthday-Detroit',{'data':'City+of+Freedom.html/City+of+Freedom.html','display':'1951 City of Freedom - 250th Birthday Detroit'}],
    ['/aboutfredfoy/1951-The-Ridgedale-Players',{'data':'Ridgedale.html/Ridgedale.html','display':'1951 The Ridgedale Players'}],
    ['/aboutfredfoy/1952-Lone-Ranger-Scripts',{'data':'Lone+Ranger+Scripts.html/Lone+Ranger+Scripts.html','display':'1952 Lone Ranger Scripts'}],
    ['/aboutfredfoy/1961-Journal:-A-Time-of-Transition',{'data':'Time+of+Transition.html/Time+of+Transition.html','display':'1961 Journal: A Time of Transition'}],
    ['/aboutfredfoy/WABC',{'data':'WABC.html/WABC.html','display':'WABC'}],
    ['/aboutfredfoy/1970-The-Dick-Cavett-Show',{'data':'Dick+Cavett.html/Dick+Cavett.html','display':'1970 The Dick Cavett Show'}],
    ['/aboutfredfoy/Head-Shots',{'data':'Head+Shots.html/Head+Shots.html','display':'Head Shots'}],
    ['/aboutfredfoy/One-Man-Show-/-An-Evening-of-Nostalgia',{'data':'Promotion.html/Promotion.html','display':'One Man Show - An Evening of Nostalgia'}],
    ['/aboutfredfoy/1972-Golden-Mike-Award',{'data':'Golden+Mike.html/Golden+Mike.html','display':'1972 Golden Mike Award'}],
    ["/aboutfredfoy/1972-Baltimore's-Golden-Age-of-Radio-Buffs",{'data':'Baltimore.html/Baltimore.html','display':"1972 Baltimore's Golden Age of Radio Buffs"}],
    ['/aboutfredfoy/1974-CLOSE-UP',{'data':'Close+Up.html/Close+Up.html','display':'1974 CLOSE UP'}],
    ['/aboutfredfoy/1975-Second-Annual-Daytime-Emmy-Awards',{'data':'Second+Annual+Daytime+Emmy.html/Second+Annual+Daytime+Emmy.html','display':'1975 Second Annual Daytime Emmy Awards'}],
    ['/aboutfredfoy/1975-50th-Anniversary-WXYZ',{'data':'50th+Anniversary.html/50th+Anniversary.html','display':'1975 50th Anniversary WXYZ'}],
    ['/aboutfredfoy/1975-The-Animal-Rescue-League',{'data':'Animal+Rescue.html/Animal+Rescue.html','display':'1975 The Animal Rescue League'}],
    ['/aboutfredfoy/Fan-Letters',{'data':'Fan+Letters.html/Fan+Letters.html','display':'Fan Letters'}],
    ['/aboutfredfoy/Birthday-Cards',{'data':'Birthday+Cards.html/Birthday+Cards.html','display':'Birthday Cards'}],
    ['/aboutfredfoy/1980-The-Legend-of-the-Lone-Ranger',{'data':'Legend+Lone+Ranger.html/Legend+Lone+Ranger.html','display':'1980 The Legend of the Lone Ranger'}],
    ['/aboutfredfoy/1986-Thirteenth-Annual-Daytime-Emmy-Awards',{'data':'Thirteenth+Annual+Emmy.html/Thirteenth+Annual+Emmy.html','display':'1986 Thirteenth Annual Daytime Emmy Awards'}],
    ['/aboutfredfoy/1988-Tune-in-Yesteryear',{'data':'Tune+In+Yesteryear.html/Tune+In+Yesteryear.html','display':'1988 Tune in Yesteryear'}],
    ['/aboutfredfoy/1989-Salute-to-Ken-Meyer',{'data':'Ken+Meyer.html/Ken+Meyer.html','display':'1989 Salute to Ken Meyer'}],
    ["/aboutfredfoy/1977-/-1993-National-Broadcaster's-Hall-of-Fame",{'data':'National+Broadcasters+Hall+of+Fame.html/National+Broadcasters+Hall+of+Fame.html','display':"1977 - 1993 National Broadcaster's Hall of Fame"}],
    ['/aboutfredfoy/1991-WATD-/-FM-85th-Anniversary-of-Broadcasting',{'data':'WATD.html/WATD.html','display':'1991 WATD - FM 85th Anniversary of Broadcasting'}],
    ['/aboutfredfoy/1992-West-Virginia-Broadcasters-Association',{'data':'West+Virginia.html/West+Virginia.html','display':'1992 West Virginia Broadcasters Association'}],
    ['/aboutfredfoy/1992-KXLY-70th-Anniversary-Deluxe-Radio',{'data':'KXLY.html/KXLY.html','display':'1992 KXLY 70th Anniversary Deluxe Radio'}],
    ['/aboutfredfoy/1993-Lone-Ranger-Radio-Days-60th-Anniversary-Festival',{'data':'Lone+Ranger+Radio+Days.html/Lone+Ranger+Radio+Days.html','display':'1993 Lone Ranger Radio Days 60th Anniversary Festival'}],
    ['/aboutfredfoy/1993-Lone-Pine-60th-Anniversary-The-Lone-Ranger',{'data':'Lone+Pine.html/Lone+Pine.html','display':'1993 Lone Pine 60th Anniversary The Lone Ranger'}],
    ['/aboutfredfoy/1993-FOTR-(Friends-of-Old-Time-Radio)',{'data':'FOTR.html/FOTR.html','display':'1993 FOTR (Friends of Old Time Radio)'}],
    ['/aboutfredfoy/1990-/-2004-Radio-Classics-Live',{'data':'Radio+Classics.html/Radio+Classics.html','display':'1990 - 2004 Radio Classics Live'}],
    ['/aboutfredfoy/1994-Meanwhile-Back-at-the-Ranch',{'data':'Meanwhile.html/Meanwhile.html','display':'1994 Meanwhile Back at the Ranch'}],
    ['/aboutfredfoy/1995-MBC-25th-Anniversary-Those-Were-The-Days',{'data':'MBC.html/MBC.html','display':'1995 MBC 25th Anniversary Those Were The Days'}],
    ["/aboutfredfoy/1996-Cincinnati's-10th-Annual-Convention",{'data':'Cincinnati.html/Cincinnati.html','display':"1996 Cincinnati's 10th Annual Convention"}],
    ['/aboutfredfoy/1998-Eastern-High-School-Reunion',{'data':'Eastern.html/Eastern.html','display':'1998 Eastern High School Reunion'}],
    ['/aboutfredfoy/1999-AFTRA-57th-National-Convention',{'data':'AFTRA+57th.html/AFTRA+57th.html','display':'1999 AFTRA 57th National Convention'}],
    ['/aboutfredfoy/1999-The-National-Lum-and-Abner-Society',{'data':'Lum.html/Lum.html','display':'1999 The National Lum and Abner Society'}],
    ['/aboutfredfoy/1999-Colonial-Chorus-Players',{'data':'Colonial+Chorus.html/Colonial+Chorus.html','display':'1999 Colonial Chorus Players'}],
    ['/aboutfredfoy/1999-Retired-Boston-Police',{'data':'Retired+Boston.html/Retired+Boston.html','display':'1999 Retired Boston Police'}],
    ['/aboutfredfoy/2000-Elmira-Little-Theatre',{'data':'Elmira.html/Elmira.html','display':'2000 Elmira Little Theatre'}],
    ['/aboutfredfoy/2000-The-Arthritis-Foundation',{'data':'Arthritis.html/Arthritis.html','display':'2000 The Arthritis Foundation'}],
    ['/aboutfredfoy/2000-Memphis-Film-Festival',{'data':'Memphis.html/Memphis.html','display':'2000 Memphis Film Festival'}],
    ['/aboutfredfoy/2000-Hollywood-Bowl',{'data':'Hollywood+Bowl.html/Hollywood+Bowl.html','display':'2000 Hollywood Bowl'}],
    ['/aboutfredfoy/2000-Radio-Hall-of-Fame',{'data':'Radio+Hall+of+Fame.html/Radio+Hall+of+Fame.html','display':'2000 Radio Hall of Fame'}],
    ['/aboutfredfoy/2004-Lone-Ranger-Radio-Days',{'data':'Lone+Ranger+Radio+Days+2004.html/Lone+Ranger+Radio+Days+2004.html','display':'2004 Lone Ranger Radio Days'}],
    ['/aboutfredfoy/2004-/-2010-The-Silver-Bullet',{'data':'Silver+Bullet.html/Silver+Bullet.html','display':'2004 - 2010 The Silver Bullet'}],
    ['/aboutfredfoy/2004-Golden-Boot-Awards',{'data':'Golden+Boot.html/Golden+Boot.html','display':'2004 Golden Boot Awards'}],
    ['/aboutfredfoy/Magazine-Articles',{'data':'Magazine.html/Magazine.html','display':'Magazine Articles'}],
    ['/aboutfredfoy/2008-Archive-of-American-Television',{'data':'Archive.html/Archive.html','display':'2008 Archive of American Television'}],
    ["/aboutfredfoy/2008-Wendy's-Interviews-with-Fred",{'data':'Interviews+with+Fred.html/Interviews+with+Fred.html','display':"2008 Wendy's Interviews with Fred"}],
    ['/aboutfredfoy/Book-Inscriptions',{'data':'Book+Inscriptions.html/Book+Inscriptions.html','display':'Book Inscriptions'}],
    ['/aboutfredfoy/Christmas-Cards',{'data':'Christmas+Cards.html/Christmas+Cards.html','display':'Christmas Cards'}],
    ['/aboutfredfoy/Sperdvac',{'data':'Sperdvac.html/Sperdvac.html','display':'Sperdvac'}],
    ['/aboutfredfoy/2010-FrednFran-Tweets',{'data':'Tweets.html/Tweets.html','display':'2010 FrednFran Tweets'}],
    ['/aboutfredfoy/2010-Obituary-Letters-and-Cards',{'data':'Obit.html/Obit.html','display':'2010 Obituary Letters and Cards'}],
    ['/aboutfredfoy/Awards',{'data':'Awards.html/Awards.html','display':'Awards'}],
    ['/aboutfredfoy/A-Little-Bit-of-Betty',{'data':'A+Little+Bit.html/A+Little+Bit.html','display':'A Little Bit of Betty'}],
    ['/aboutfredfoy/Hannah-Family-School-Papers',{'data':'Hannahs+Family+School+Papers.html/Hannahs+Family+School+Papers.html','display':"Hannah's Family School Papers"}],
    ['/aboutfredfoy/Bettys-Archive-Vintage-Family-China',{'data':'Betty+Archive+Vintage+Family+China.html/Betty+Archive+Vintage+Family+China.html','display':"Betty's Archive Vintage Family China"}],
    ['/aboutfredfoy/Good-Friend-Don-Sherwood',{'data':'Don+Sherwood.html/Don+Sherwood.html','display':'Good Friend Don Sherwood'}],
    ['/aboutfredfoy/Dads-Nelson-Eddy-Scrapbook',{'data':'Nelson+Eddy+Scrapbook.html/Nelson+Eddy+Scrapbook.html','display':"Dad's Nelson Eddy Scrapbook"}],
    ['/aboutfredfoy/Lone-Ranger-Memorabilia',{'data':'Lone+Ranger+Memorabilia.html/Lone+Ranger+Memorabilia.html','display':'Lone Ranger Memorabilia'}],
    ['/aboutfredfoy/More-Memories',{'data':'More+Memories.html/More+Memories.html','display':'More Memories'}],
    ['/aboutfredfoy/Newspaper-Clippings',{'data':'Newspaper.html/Newspaper.html','display':'Newspaper Clippings'}],
    ['/aboutfredfoy/More-Foy-Family-Pictures',{'data':'More+Foy+Family.html/More+Foy+Family.html','display':'More Foy Family Pictures'}],
    ['/aboutfredfoy/Fran-Foy-Portfolio',{'data':'Portfolio.html/Portfolio.html','display':'Fran Foy Portfolio'}],
    ['/aboutfredfoy/Fran-Foy-Art-Exhibitions-and-Awards',{'data':'Fran+Foy+Exhibitions.html/Fran+Foy+Exhibitions.html','display':'Fran Foy Art Exhibitions and Awards'}],
    ['/aboutfredfoy/Wendy',{'data':'Wendy.html/Wendy.html','display':'Wendy'}]
  ]
}