var descriptions = {
  "fredansweringmachine.mp3":{ "info":"Fred's recorded answering machine message 2010"},
  "Ghost+of+the+Lone+Ranger.mp4":{ "info":"Ghost of the Lone Ranger - 2015 - Featuring Fred Foy - Starring John Hart, Rob Archey, Scott Koehle, Richard Volpe, Maude Sherrill, James Brantner, Luke Archey, Dave Jacobs, Joe Archey"},
  "Fred+Foy+Hollywood+Bowl+August+2000.mp4":{ "info":"William Tell Overture (finale) (with fireworks) - In Tribute to Clayton Moore - The Lone Ranger - John Mauceri, conductor"},
  "Phil+Gries+Interview+Fred+Foy+2010.mp3":{ "info":"Fred Foy in phone conversations with Phil Gries (Collector's Choice Archival Television Audio, Inc.) - 7-30-2010 and 8-7-2010"},
  "This+Is+ABC+Radio.mp3":{ "info":"This is ABC Radio"},
  "Jordan+Rich+Tribute+to+Fred+Foy.mp3":{ "info":"Jordan Rich Tribute to Fred Foy"},
  "01+Bobb+Lynes+Interview.mp3":{ "info":"Bobb Lynes Interview with Fred Foy - The Old Time Radio Show - 10-12-1985.  Thank you Bobb Lynes for this great addition to the website!"},
  "Schnitzelbank+track+07.mp3":{ "info":"A traditional German song more widely known and enjoyed throughout the world than almost any other"},
  "Green+Hornet+and+Yukon+Openings.mp3":{ "info":"Green Hornet and Yukon Openings"},
  "Fred+Foy+Radio+Spots.mp3":{ "info":"MGM Movie Trailers - Promos - 'The Outrage', 'Around The World Under The Sea', 'Maya', 'The Hill', 'Hawaii' (United Artists), 'Dr. Zhivago', 'Gone With The Wind', 'Grand Prix', 'Operation Crossbow'"},
  "The+Story+of+the+Pilgrims+side+01.mp3":{ "info":"'The Story of the Pilgrims’ - Produced and Written by H. Paul Jeffers and Narrated by Fred Foy - Part One - 1967"},
  "The+Story+of+the+Pilgrims+side+02.mp3":{ "info":"'The Story of the Pilgrims’ - Produced and Written by H. Paul Jeffers and Narrated by Fred Foy - Part Two - 1967"},
  "William+Tell+Overture+and+Origin.mp4":{ "info":"William Tell Overture and Origin."},
  "Bing+Crosby+Collection+Gather+Lilacs.mp3":{ "info":"Bing Crosby Collection Gather Lilacs"},
  "Andy+Southard+20th+Anniv+LR+Show+01301953.mp3":{ "info":"Andy Southard 20th Anniversary Show 01-30-1953"},
  "Andy+Southard+Final+LR+Show+05-27-1955.mp3":{ "info":"Andy Southard Final Show 05-27-1955"},
  "Andy+Southard+Original+Who+Was+That+Masked+Man+Montage.mp3":{ "info":"Original 'Who Was That Masked Man' Montage"},
  "Bobb+Lynes+OTRC+KCRW+10201985.mp3":{ "info":"Bobb Lynes - Old Time Radio Show KCRW 10-20-1985 (Dad does a personalized opening to Bobb's show) (Bobb is very emotional talking about playing a part with Dad in a Lone Ranger recreation FOTR Newark 10-12-1985)  Tom McDougal was there, Ernie Winstanley, Jim Fletcher, Lee Allen played the Lone Ranger, Mrs. Brace Beemer was in the audience.  The WXYZ reunion."},
  "WCCM+Fred+Foy+11091987.mp3":{ "info":"WCCM Radio 800 AM - 'Thanks For The Memories' PT. 7 11-09-1987 (Fred Foy with Jimi Carter) Merrimack College (phone interview)"},
  "Lone+Ranger+Christmas+Show+Three+Wise+Men+Side+One.mp3":{ "info":"The Lone Ranger Christmas Show 'Three Wise Men' (with commercials)"},
  "Jack+Benny+Cairo+1943+(scratchy+quality).mp4":{ "info":"Jack Benny - Cairo, Egypt - 1943 (scratchy) Larry Adler, Wini Shaw, Anna Lee, Corporal Fred Foy - A special transmission by the USO from Cairo, Egypt by Jack Benny's USO touring group."},
  "Jack+Benny+19.30+minutes.mp4":{ "info":"Jack Benny - 19.30 minutes"},
  "Jack+Benny2+2733+minutes.mp3":{ "info":"Jack Benny - Cairo, Egypt - 1943 (scratchy) Larry Adler, Wini Shaw, Anna Lee, Corporal Fred Foy - A special transmission by the USO from Cairo, Egypt by Jack Benny's USO touring group."},
  "Jack+Benny+Cairo+1943+Part+1.mp3":{ "info":"Jack Benny Cairo Part One"},
  "Jack+Benny+Cairo+1943+Part+2.mp3":{ "info":"Jack Benny Cairo Part Two"},
  "Jack+Benny+Cairo+Intrigue+Dads+Tape.mp4":{ "info":"Jack Benny Cairo Dad's Tape 1"},
  "Jack+Benny+Cairo+Intrigue+Dads+Tape2.mp3":{ "info":"'Intrigue' - 07-31-1946 - 'Dawn over the Amazon' - Taped in Hollywood - Fred Foy as Grant Hammond The quality is scratchy but it's really fun to listen to my Dad in his role as Grant Hammond! Other characters: Marcella, The Reeper Monteze, Hammond, Carlton Beals, Baron de Braga, Montague"},
  "Intrigue+Dads+Tape.mp4":{ "info":"Intrigue Dad's Tape"},
  "Intrigue+PT1.mp3":{ "info":"Intrigue Part One"},
  "Intrigue+PT2.mp3":{ "info":"Intrigue Part Two"},
  "Intrigue+Part+One.m4v":{ "info":"Intrigue Part One"},
  "Intrigue+Part+Two.m4v":{ "info":"Intrigue Part Two"},
  "Intrigue2.mp4":{ "info":"Intrigue"},
  "When+Radio+Was.mp3":{ "info":"'When Radio Was' - Sunday Edition - WMAQ - Carl Amari"},
  "When+Radio+Was.mp4":{ "info":"'When Radio Was' - Sunday Edition - WMAQ - Carl Amari"},
  "Ken+Meyer+with+Fred+Foy+and+Clayton+Moore+WEEI+Boston.mp3":{ "info":"Fred Foy and Clayton Moore with Ken Meyer WEEI Boston"},
  "Haunted+Eye+Fred+Foy+and+Jim+Harmon.mp3":{ "info":"'Haunted Eye' with Fred Foy and Jim Harmon"},
  "Golden+Days1.mp3":{ "info":"'Golden Days of Radio' - Frank Bresee - Guest Fred Foy - Part One"},
  "Golden+Days+2.mp3":{ "info":"'Golden Days of Radio' - Frank Bresee - Guest Fred Foy - Part Two"},
  "Golden+Days+3.mp3":{ "info":"'Golden Days of Radio' - Frank Bresee - Guest Fred Foy - Part Three"},
  "Golden+Days+4.mp3":{ "info":"'Golden Days of Radio' - Frank Bresee - Guest Fred Foy - Part Four"},
  "Golden+Days+5.mp3":{ "info":"'Golden Days of Radio' - Frank Bresee - Guest Fred Foy - Part Five"},
  "20th+Anniv.+LR+1953.mp4":{ "info":"20th Anniversary The Lone Ranger 1953"},
  "20th+Anniv.+LR+Show+01-30-1953.mp3":{ "info":"20th Anniversary The Lone Ranger Show 01/30/1953"},
  "20th+Anniv.+LR2.mp4":{ "info":"20th Anniversary The Lone Ranger"},
  "20th+Anniv.+Show.mp3":{ "info":"20th Anniversary The Lone Ranger"},
  "20th+Anniv.+Show.mp4":{ "info":"20th Anniversary The Lone Ranger"},
  "20th+Anniversary+Dads+Tape+1953.mp4":{ "info":"20th Anniversary The Lone Ranger Dad's copy"},
  "20th+Anniversary+Lone+Ranger+Show.mp3":{ "info":"The Lone Ranger 20th Anniversary Program - 'Return of Cavendish' - January 30, 1953 - Fred Foy with a Supporting Cast"},
  "Nostalgia+Scrapbook+Tribute+to+the+Lone+Ranger.mp3":{ "info":"'Nostalgia Scrapbook' - 7th Show - 'Tribute to The Lone Ranger' with Barry Lunderville and Mel Simons. Telephone interview with Fred Foy."},
  "Trailers+to+Integrate.mp3":{ "info":"Trailers to Integrate (Doctor Zhivago, Advice to the Lovelorn, None But The Brave, The Outrage, Operation Crossbow, Sergeant Preston of the Yukon, Green Hornet, Theatre Five)"},
  "Trailers.mp4":{ "info":"Movie Trailers"},
  "RHAC+1987.mp3":{ "info":"RHAC 1987 - Radio Historical Association of Colorado RHAC 3rd Annual Convention 09-12-1987\n Ethel and Albert - 'Dutch Treat' - Starring Peg Lynch and Fred Foy\n Ethel and Albert - 'Ethel is Driving Albert to Work' - starring Peg Lynch, Fred Foy and Lon Clark - Part One"},
  "RHAC2.mp3":{ "info":"RHAC2 - Radio Historical Association of Colorado RHAC 3rd Annual Convention 09-12-1987\n Nick Carter, Master Detective 11-13-1949 'Case of the Man Who Died Laughing' - starring Lon Clark,\n Fred Foy, Michelle Clark, Reid Hansen, and Jeanette Dran - Part Two"},
  "KPBX+70th+Anniversary+Fred+Foy.mp3":{ "info":"KPBX 70th Anniversary Deluxe Radio Show - Warren Harshbarger - October 1992 - A very interesting interview."},
  "Frank+Brisee+The+Golden+Days+of+Radio.mp3":{ "info":"The Golden Days of Radio - Frank Bresee - A Special Yesterday USA Radio Edition of The Golden Days of Radio - A Salute to Fred Foy - 1.30 minutes with Frank Bresee 2. A Lone Ranger episode 3. A Fond Remembrance and Induction into the Hall of Fame."},
  "Detroit+Producers+Assoc+Pioneer+Radio+Night+Full+12061979.mp3":{ "info":"Detroit Producer's Association Pioneer Awards - Fourth Annual Pioneer Night - December 6, 1979 - Revisit the Magic Moments of Radio - In the audience Garnet Garrison - WXYZ radio 1931- Professor WSU, Producer at NBC and Professor University of Michigan - Special Tribute to Radio - Honorees - William Tell Overture - A Lone Ranger episode - Sergeant Preston of the Yukon episode - Green Hornet\n Honorees: Wally Keep, Jim Lawrence, Bob Martin, Al Murdoch, Dick Osgood, Hank Stanball, Elaine Alpert Stern, Wit Vernon, Liz Weiss, Betty Wright, Jim Fletcher, Ernie Winstanley, Dan Beattie (writer), Tony Caminita, Tom Dougall (writer), Fred Flowerday (director), Sandy Forsythe, Jean Fletcher, Fred Foy, Harry Goldstein, Joe Gentile, Bill Henstebeck (sound effects), Jim Henstebeck, Paul Hughes (Thunder Martin), Betty Joyce (writer)"},
  "Ken+Meyer/FF.mp4":{ "info":"A great interview by Ken Meyer - guest on phone Jim Fletcher"},
  "Ken+Meyer2.mp3":{ "info":"A great interview by Ken Meyer - guest on phone Jim Fletcher"},
  "Ken+Meyer+Interview.mp4":{ "info":"A great interview by Ken Meyer - guest on phone Clayton Moore"},
  "Ken+Meyer+-+Fred+Foy.mp4":{ "info":"A great interview by Ken Meyer"},
  "Ken+Meyer+Interview+10-03-1986+Guest+Fred+Foy.mp3":{ "info":"A great interview by Ken Meyer"},
  "Special+VJ+Day+Show+Sept_2_1945.mp3":{ "info":"Special V-J Day Show - September 2, 1945 - President of the United States delivers message.  Bing Crosby, Frank Sinatra, Frances Landford"},
  "Intrigue+taped+in+Hollywood+7-31-1946.mp4":{ "info":"'Intrigue' - 07-31-1946 - 'Dawn over the Amazon' - Taped in Hollywood - Fred Foy as Grant Hammond The quality is scratchy but it's really fun to listen to my Dad in his role as Grant Hammond! Other characters: Marcella, The Reeper Monteze, Hammond, Carlton Beals, Baron de Braga, Montague"},
  "Easter+Bunny+Bunny.m4v":{ "info":"Easter Bunny Bunny"},
  "Easter+Bunny+Bunny.mp3":{ "info":"Easter Bunny Bunny"},
  "Race+to+the+Wire3.mp3":{ "info":"'Race to the Wire' - 05-25-1955 - General Mills Commercials"},
  "Three+Wise+Men.mp4":{ "info":"'Three Wise Men' - General Mills Commercials"},
  "Radio+Hall+of+Fame+-+Chicago.mp3":{ "info":"Radio Hall of Fame 2000 - Chicago"},
  "Burly+Scotts+Sacrifice+03291954.mp4":{ "info":"Burly Scott's Sacrifice 03-29-1954"},
  "Burly+Scotts+Sacrifice+03291954.mp3":{ "info":"Burly Scott's Sacrifice 03-29-1954"},
  "Fred+Foy+Stars+as+The+Lone+Ranger+Burley+Scotts+Sacrifice+03_29_1954.mp3":{ "info":"Fred Foy As The Lone Ranger - 'Burly Scott's Sacrifice' - March 29, 1954 - Program #3309/2529 - FRED FOY AS THE LONE RANGER - NOT Brace Beemer as the LR but Fred Foy!!!!  Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "Lum+and+Abner.mp3":{ "info":"1. Lum & Abner meet The Lone Ranger starring Fred Foy as Announcer-Narrator and the 'Masked Man' with Sam Brown, Tim Hollis and Donnie Pitchford.  2. Lum and Abner - June 12, 1949 Recreation starring Bob Cates as Opie Cates and Fred Foy as Wendall Niles with Sam Brown, Tim Hollis, Verla Huckaby, Laura Pitchford and Donnie Pitchford.  June 25 & 26, 1999 - Mena, Arkansas"},
  "The+Lone+Ranger+-+The+Origin+of+the+Lone+Ranger+-+Fred+Foy.mp3":{ "info":"The Origin of The Lone Ranger"},
  "The+Lone+Ranger+15th+Anniversary+Show+The+Origins+Of+The+Lone+Ranger.mp3":{ "info":"15th Anniversary Show The Origin of The Lone Ranger"},
  "The+Origin+of+The+Lone+Ranger+-+Decca+Records.mp3":{ "info":"The Origin of The Lone Ranger"},
  "The+Lone+Rangers+Origin+06301948.mp4":{ "info":"The Lone Rangers Origin 6-30-1948"},
  "Theatre+Five+Compilation.mp3":{ "info":"Short Takes from 'Across The River From Grandma's House', 'The Wheel', 'The Imposters' and 'A Little Knowledge' - ABC Radio Network"},
  "Theatre+Five.mp3":{ "info":"Theatre Five - With announcer Fred Foy for the ABC Network - 'Across The River From Grandma's House' 01-19-1965 (Dad acts in this one), 'A Dream Of A Scheme' 01-20-1965 and 'The Imposters' 01-21-1965 (Dad acts in this one)"},
  "Voices+in+the+Headlines+-+Fred+Foy+-+Feb+2+1964+-+The+Lone+Ranger+Ends+Broadcasting+On+ABC+Radio.mp3":{ "info":"Voices In The Headlines - 02-02–1964 - “The Lone Ranger ends broadcasting on ABC Radio” - Fred Foy"},
  "Voices+In+The+Headlines+Watts+Riot+Fred+Foy+08151965.mp3":{ "info":"Voices In The Headlines - 'Watts Riot' - 08-15-1965 - Fred Foy"},
  "Voices+in+the+Headlines+-+Fred+Foy+-+02_02_1964+-+The+Lone+Ranger+Ends+Broadcasting+On+ABC+Radio.mp3":{ "info":"Voices In The Headlines - 02-02–1964 - “The Lone Ranger Ends Broadcasting On ABC Radio” - Fred Foy"},
  "The+Fear+That+Creeps+Like+A+Cat.mp3":{ "info":"The Fear That Creeps Like A Cat Part 1 - Carlton E. Morse's I Love A Mystery - With Radio Greats Fred Foy, Les Tremayne and Frank Bresee"},
  "The+Fear+That+Creeps+Like+A+Cat2.mp3":{ "info":"The Fear That Creeps Like A Cat Part 2 - Carlton E. Morse's I Love A Mystery - With Radio Greats Fred Foy, Les Tremayne and Frank Bresee"},
  "The+Fear+That+Creeps+Like+A+Cat3.mp3":{ "info":"The Fear That Creeps Like A Cat Part 3 - Carlton E. Morse's I Love A Mystery - With Radio Greats Fred Foy, Les Tremayne and Frank Bresee"},
  "The+Best+of+Chuck+Schadens+Those+Were+The+Days+25th+Anniversary+Show+Part+One.mp3":{ "info":"The Best of Chuck Schaden's 'Those Were The Days' 25th Anniversary Show Part One - Originally Broadcast Saturday, April 29, 1995 - WNIB Chicago 97.1 FM"},
  "The+Best+of+Chuck+Schadens+Those+Were+The+Days+25th+Anniversary+Show+Part+Two.mp3":{ "info":"The Best of Chuck Schaden's 'Those Were The Days' 25th Anniversary Show Part Two - Originally Broadcast Saturday, April 29, 1995 - WNIB Chicago 97.1 FM"},
  "Meanwhile+Back+At+The+Ranch+-+Fred+Foy+Remembers.mp4":{ "info":"'Meanwhile Back At The Ranch…' - Fred Foy Remembers - A Fond Recollection"},
  "Meanwhile+Back1.mp3":{"info":"'Meanwhile Back At The Ranch…' Part One - Fred Foy Remembers - A Fond Recollection"},
  "Meanwhile+Back2.mp3":{ "info":"'Meanwhile Back At The Ranch…' Part Two - Fred Foy Remembers - A Fond Recollection"},
  "Meanwhile+Back3.mp3":{ "info":"'Meanwhile Back At The Ranch…' Part Three - Fred Foy Remembers - A Fond Recollection"},
  "Dad+as+Sergeant+Preston+1954.mp3":{ "info":"Sergeant Preston of the Yukon - 1. Fred Foy stars in 'Deed to the Future' and  2. 'Gunfire'"},
  "02+Fred+Foy+Stars+as+Sergeant+Preston+of+the+Yukon+12_30_1954.mp4":{ "info":"Sergeant Preston of the Yukon - 1. Deed to the Future - 02-01-1955 - 2. Gunfire - 02/10/1955"},
  "The+Live+Show+-+Radio+Once+More+(Part+One)+-+Fred+Foy+-+Terry+Salomonson.mp3":{ "info":"'The Live Show' - Radio Once More with Neil Ellis and Ken Stockinger - Terry Salomonson - Fred Foy - Part One - The Lone Ranger"},
  "The+Live+Show+-+Radio+Once+More+(Part+Two)+-+Fred+Foy.mp3":{ "info":"'The Live Show' - Radio Once More with Neil Ellis and Ken Stockinger - Terry Salomonson - Fran Striker Jr. - Part Two - The Lone Ranger"},
  "The+Live+Show+-+Radio+Once+More+(Part+Three)+-+Fred+Foy.mp3":{ "info":"'The Live Show' - Radio Once More with Neil Ellis and Ken Stockinger - Terry Salomonson - Fran Striker Jr. - Part Three - The Lone Ranger"},
  "Bob+Mest+Hello+Lois!+with+Fred+Foy+and+James+E.+Naylor.mp3":{ "info":"Robert Frederick Mest (Bob Mest) - Army - Cairo, Egypt - Circa 1942 - Fred Foy and James Naylor"},
  "President+Kennedy+Assassination+narrated+by+Fred+Foy+ABC+Radio+News+11_22-11_25_1963.mp3":{ "info":"President John F. Kennedy's Assassination - Comprehensive coverage from the outset reported by Don Gardiner and the staff of ABC Radio News in New York, Dallas and around the world… - narrated by Fred Foy - ABC Radio News 11-22-1963 - 11-25-1963"},
  "President+Kennedy+Assassination+(Part+Two)+narrated+by+Fred+Foy+ABC+Radio+News.mp3":{ "info":"President John F. Kennedy's Assassination - Comprehensive coverage from the outset reported by Don Gardiner and the staff of ABC Radio News in New York, Dallas and around the world… - narrated by Fred Foy - ABC Radio News 11-22-1963 - 11-25-1963"},
  "President+Kennedy+Assassination+(Part+Three)+narrated+by+Fred+Foy+ABC+Radio+News+11_22-11_25_1963.mp3":{ "info":"President John F. Kennedy's Assassination - Comprehensive coverage from the outset reported by Don Gardiner and the staff of ABC Radio News in New York, Dallas and around the world… - narrated by Fred Foy - ABC Radio News 11-22-1963 - 11-25-1963"},
  "JFK+Assassination+Coverage+(concluding+segment)+narrator+Fred+Foy+ABC+Radio+News+11_25_1963.mp3":{ "info":"JFK Assassination Coverage (concluding segment) Featuring Don Gardiner, narrator Fred Foy - ABC Radio News - November 24, 1963, On The Line w/Bob Considine - Focus: Congressman Adam Clayton Powell - Announcer: Herb Oscar Anderson - System Cue Voiced by Fred Foy - ABC Radio News 02-10-1967"},
  "The+Eagle+and+The+Bear+-+1+Introduction+-+The+Allies+-+narrator+Fred+Foy+-+09_21_1966.mp3":{ "info":"The Eagle and The Bear - A radio chronicle of the Cold War - written by H. Paul Jeffers - narrator Fred Foy - #1 'Introduction - The Allies' 09-21-1966"},
  "The+Eagle+and+The+Bear+-+2+Potsdam+-+narrator+Fred+Foy+-+09_28_1966.mp3":{ "info":"The Eagle and The Bear - A radio chronicle of the Cold War - written by H. Paul Jeffers - narrator Fred Foy - #2 'Potsdam' 09-28-1966"},
  "The+Eagle+and+The+Bear+-+3+Emphasis+Europe+-+narrator+Fred+Foy+-+10_05_1966.mp3":{ "info":"The Eagle and The Bear - A radio chronicle of the Cold War - written by H. Paul Jeffers - narrator Fred Foy - #3 'Emphasis on Europe' 10-05-1966"},
  "The+Eagle+and+The+Bear+-+4+Eagles+in+the+Corridor+-+narrator+Fred+Foy+-+10_12_1966.mp3":{ "info":"The Eagle and The Bear - A radio chronicle of the Cold War - written by H. Paul Jeffers - narrator Fred Foy - #4 'Eagles in the Corridor' 10-12-1966"},
  "The+Eagle+and+The+Bear+-+5+The+Red+Menace+-+narrator+Fred+Foy+-+10_19_1966.mp3":{ "info":"The Eagle and The Bear - A radio chronicle of the Cold War - written by H. Paul Jeffers - narrator Fred Foy - #5 'The Red Menace' 10-19-1966"},
  "The+Eagle+and+The+Bear+-+6+Korea+-+The+Decision+to+Fight+-+narrator+Fred+Foy+-+10_26_1966.mp3":{ "info":"The Eagle and The Bear - A radio chronicle of the Cold War - written by H. Paul Jeffers - narrator Fred Foy - #6 'Korea - The Decision to Fight' 10-26-1966"},
  "The+Eagle+and+The+Bear+-+7+Korea+-+The+Decision+to+Quit+-+narrator+Fred+Foy+-+11_02_1966.mp3":{ "info":"The Eagle and The Bear - A radio chronicle of the Cold War - written by H. Paul Jeffers - narrator Fred Foy - #7 'Korea - The Decision to Quit' - 11-02-1966"},
  "The+Eagle+and+The+Bear+-+8+The+Age+of+Coexistence+-+narrator+Fred+Foy+-+11_09_1966.mp3":{ "info":"The Eagle and The Bear - A radio chronicle of the Cold War - written by H. Paul Jeffers - narrator Fred Foy - #8 'Korea - The Age of Coexistence' - 11-09-1966"},
  "The+Eagle+and+The+Bear+-+9+Over+Flight+and+Over+Kill+-+narrator+Fred+Foy+-+11_16_1966.mp3":{ "info":"The Eagle and The Bear - A radio chronicle of the Cold War - written by H. Paul Jeffers - narrator Fred Foy - #9 'Over Flight and Over Kill' 11-16-1966"},
  "The+Eagle+and+The+Bear+-+10+The+Uncommitted+-+narrator+Fred+Foy+-+11_23_1966.mp3":{ "info":"The Eagle and The Bear - A radio chronicle of the Cold War - written by H. Paul Jeffers - narrator Fred Foy - #10 'The Committed' 11-23-1966"},
  "The+Eagle+and+The+Bear+-+11+Two+Weeks+in+October+-+narrator+Fred+Foy+-+11_30_1966.mp3":{ "info":"The Eagle and The Bear - A radio chronicle of the Cold War - written by H. Paul Jeffers - narrator Fred Foy - #11 'Two Weeks in October' 11-30-1966"},
  "The+Eagle+and+The+Bear+-+12+New+Faces+of+1964+-+narrator+Fred+Foy+-+12_07_1966.mp3":{ "info":"The Eagle and The Bear - A radio chronicle of the Cold War - written by H. Paul Jeffers - narrator Fred Foy - #12 'New Faces of 1964' 12-07-1966"},
  "The+Eagle+and+The+Bear+-+13+The+Eagle+The+Bear+and+The+Dragon+-+written+by+H+Paul+Jeffers+-+narrator+Fred+Foy+-+12_14_1966.mp3":{ "info":"The Eagle and The Bear - A radio chronicle of the Cold War - written by H. Paul Jeffers - narrator Fred Foy - #13 'The Eagle, The Bear and The Dragon' - 12-14-1966"},
  "The+Lone+Ranger+-+Green+Cross+Safety+Spots.mp3":{ "info":"The Lone Ranger - Green Cross Safety Spots - Jay Walking"},
  "The+Lone+Ranger+-+Green+Cross+Safety+Spots2.mp3":{ "info":"The Lone Ranger - Green Cross Safety Spots - Guns"},
  "The+Lone+Ranger+-+Green+Cross+Safety+Spots3.mp3":{ "info":"The Lone Ranger - Green Cross Safety Spots - Swimming"},
  "The+Lone+Ranger+-+The+Adventure+of+the+Bluefield+Prison+Break+-+Fred+Foy.mp3":{ "info":"The Lone Ranger - 'The Adventure of the Bluefield Prison Break' - Fred Foy"},
  "The+Lone+Ranger+-+The+Adventure+of+the+Lone+Ranger+and+The+War+Horse+-+Fred+Foy.mp3":{ "info":"The Lone Ranger - 'The Adventure of the Lone Ranger and The War Horse' - Fred Foy"},
  "The+Lone+Ranger+-+The+Legend+of+the+Lone+Ranger+and+the+Colonels+Son+-+Fred+Foy.mp3":{ "info":"The Lone Ranger - 'The Legend of the Lone Ranger and the Colonel's Son' - Fred Foy"},
  "The+Lone+Ranger+-+The+Legend+of+Dan+Reid+-+The+story+of+how+the+Lone+Ranger+found+his+only+living+relative+-+Fred+Foy.mp3":{ "info":"The Lone Ranger - 'The Legend of Dan Reid' - The story of how the Lone Ranger found his only living relative. - Fred Foy"},
  "The+Lone+Ranger+-+The+Story+of+How+Tonto+Saved+The+Lone+Ranger+-+Fred+Foy.mp3":{ "info":"The Lone Ranger - 'The Story of the Lone Ranger and his Great Horse Silver' - Fred Foy"},
  "The+Lone+Ranger+-+The+Lone+Ranger+and+the+Booneville+Gold+-+Fred+Foy.mp3":{ "info":"The Lone Ranger - 'The Lone Ranger and the Booneville Gold' - Fred Foy"},
  "The+Lone+Ranger+-+Stranger+From+The+East+-+Fred+Foy.mp3":{ "info":"The Lone Ranger - 'The Stranger From The East' - Fred Foy"},
  "The+Lone+Ranger+-+The+Story+of+the+Lone+Ranger+and+his+Great+Horse+Silver+-+Fred+Foy.mp3":{ "info":"The Lone Ranger - 'The Story of the Lone Ranger and his Great Horse Silver' - Fred Foy"},
  "The+Lone+Ranger+-+Those+Thrilling+Days+of+Yesterday.mp3":{ "info":"The Lone Ranger - George W. Trendle - 'Those Thrilling Days of Yesteryear' - From the University of Michigan Television Center - The history and nostalgia of a 20th Century art form, Golden Radio Drama - host: Dr. Edgar Willis - professor of Speech and Broadcasting at the University of Michigan"},
  "Colombo+Bonnell+Desert+Riders.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Colombo/Bonnell: Desert Riders"},
  "Beethoven+Sonata+12+Funeral+-+3rd+Movement+-+Excerpt+1.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Beethoven: Sonata # 12 'Funeral' - 3rd movement - excerpt # 1"},
  "Beethoven+Sonata+12+Funeral+-+3rd+Movement+-+Excerpt+2.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Beethoven: Sonata # 12 'Funeral' - 3rd movement - excerpt # 2"},
  "Strauss+Johann+Jr+Die+Fledermaus+Overture+-+Excerpt+1.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Strauss, Johann Jr: Die Fledermaus Overture - excerpt # 1"},
  "Strauss+Johann+Jr+Die+Fledermaus+Overture+-+Excerpt+2.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Strauss, Johann Jr: Die Fledermaus Overture - excerpt # 2"},
  "Colombo+Bonnell+Lamento.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Colombo/Bonnell: Lamento"},
  "Weber+Der+Freischutz+Overture.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Weber: Der Freischutz Overture"},
  "Nicolai+Merry+Wives+Of+Windsor+Overture.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Nicolai: Merry Wives Of Windsor Overture"},
  "Strauss+Richard+Festmarsch+Op+1.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Strauss, Richard: Festmarsch, Op. 1"},
  "Berlioz+Benvenuto+Cellini+Overture.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Berlioz: Benvenuto Cellini Overture"},
  "Hajos+Bonnell+Mysterioso+1.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Hajos/Bonnell: Mysterioso # 1"},
  "Gomez+Il+Guarany+Overture+-+Excerpt+1.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Gomez: Il Guarany Overture - excerpt # 1"},
  "Gomez+Il+Guarany+Overture+-+Excerpt+2.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Gomez: Il Guarany Overture - excerpt # 2"},
  "Schubert+Rosamunde+Overture.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Schubert: Rosamunde Overture"},
  "Hajos+Bonnell+Life+Saver.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Hajos/Bonnell: Life Saver"},
  "Beethoven+Egmont+Overture+-+Excerpt+1.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Beethoven: Egmont Overture - excerpt # 1"},
  "Beethoven+Egmont+Overture+-+Excerpt+2.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Beethoven: Egmont Overture - excerpt # 2"},
  "Beethoven+Egmont+Overture+-+Excerpt+3.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Beethoven: Egmont Overture - excerpt # 3"},
  "Colombo+Bonnell+Heavy+Agitato+1.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Colombo/Bonnell: Heavy Agitato # 1"},
  "Berlioz+Roman+Carnival+Overture.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Berlioz: Roman Carnival Overture"},
  "Strauss+Johann+Jr+One+Night+In+Venice+Overture.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Strauss, Johann Jr: One Night In Venice Overture"},
  "Borodin+Polovetsian+Dances+(Band+Arrangement).mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Borodin: Polovetsian Dances (band arrangement)"},
  "Mendelssohn+Ruy+Blas+Overture+-+Excerpt+1.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Mendelssohn: Ruy Blas Overture - excerpt # 1"},
  "Mendelssohn+Ruy+Blas+Overture+-+Excerpt+2.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Mendelssohn: Ruy Blas Overture - excerpt # 2"},
  "Weber+Oberon+Overture.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Weber: Oberon Overture"},
  "Colombo+Lava+Bonnell+The+Fighting+Devil+Dogs+End+Title.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Colombo/Lava/Bonnell: The Fighting Devil Dogs End Title"},
  "Bizet+Patrie+Overture.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Bizet: Patrie Overture"},
  "Reznicek+Donna+Diana+Overture+-+Excerpt+1+(Sgt.+Preston+Of+The+Yukon+Theme).mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Reznicek: Donna Diana Overture - excerpt # 1 (Sgt. Preston Of The Yukon theme)"},
  "Reznicek+Donna+Diana+Overture+-+Excerpt+2.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Reznicek: Donna Diana Overture - excerpt # 2"},
  "Reznicek+Donna+Diana+Overture+-+Excerpt+3.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Reznicek: Donna Diana Overture - excerpt # 3"},
  "Colombo+Bonnell+The+Lone+Ranger+Foreword.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Colombo/Bonnell: The Lone Ranger Foreword"},
  "Beethoven+Symphony+7+-+2nd+Movement.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Beethoven: Symphony # 7 - 2nd movement"},
  "Flotow+Stradella+Overture.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Flotow: Stradella Overture"},
  "Liszt+Mazeppa+-+Excerpt+1.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Liszt: Mazeppa - excerpt # 1"},
  "Liszt+Mazeppa+-+Excerpt+2.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Liszt: Mazeppa - excerpt # 2"},
  "Colombo+Bonnell+The+Getaway+-+Part+1.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Colombo/Bonnell: The Getaway - Part 1"},
  "Suppe+Franz+Von+Wiener+Jubel+Overture.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Suppe, Franz Von: Wiener Jubel Overture"},
  "Mendelssohn+Symphony+3+Scotch+-+Scherzo+-+Excerpt+1.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Mendelssohn: Symphony # 3 'Scotch' - Scherzo - excerpt # 1"},
  "Mendelssohn+Symphony+3+Scotch+-+Scherzo+-+Excerpt+2.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Mendelssohn: Symphony # 3 'Scotch' - Scherzo - excerpt # 2"},
  "Hajos+Bonnell+Mechanical+Montage.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Hajos/Bonnell: Mechanical Montage"},
  "Wagner+Rienzi+Overture+-+Excerpt+1.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Wagner: Rienzi Overture - excerpt # 1"},
  "Wagner+Rienzi+Overture+-+Excerpt+2.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Wagner: Rienzi Overture - excerpt # 2"},
  "Wagner+Rienzi+Overture+-+Excerpt+3.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Wagner: Rienzi Overture - excerpt # 3"},
  "Colombo+Bonnell+The+Lone+Ranger+Main+Title.mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Colombo/Bonnell: The Lone Ranger Main Title"},
  "Rimsky-Korsakov+The+Flight+Of+The+Bumble+Bee+(Theme-+The+Green+Hornet).mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Rimsky-Korsakov: The Flight Of The Bumble Bee (Theme- The Green Hornet)"},
  "Charles+Williams+Winged+Messenger+(The+Silver+Eagle+Opening+Theme).mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Charles Williams: Winged Messenger (The Silver Eagle opening theme)"},
  "Walter+Collins+The+Great+Assembly+March+(The+Silver+Eagle+Closing+Theme).mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - Walter Collins: The Great Assembly March (The Silver Eagle closing theme)"},
  "A+Lone+Ranger+Opener+To+End+All+Lone+Ranger+Openers+(Private+Recording).mp3":{ "info":"The Lone Ranger Music CD Vol. 2 - 48 Musical Cuts and Bridges Used for the Broadcasts - A Lone Ranger Opener To End All Lone Ranger Openers (Private recording)"},
  "The+Lone+Ranger+-+Music+and+Miscellaneous+Items+and+Comedy+Bits.mp3":{ "info":"The Lone Ranger Music CD Vol. 3 - Music and Miscellaneous Items and Comedy Bits\n The Lone Ranger Music – Plus Miscellaneous Items\n (track 1)\n 1.  Lone Ranger comedy monologue by Bill Cosby\n 2.  An Excerpt from Philco Radio Time - Bing Crosby's special\n guest is Brace Beemer (comedy skit)\n 3.  Lone Ranger scene - Ranger shoots Silver\n 4.  Piltdown Rides Again\n 5.  Lone Ranger 'Decca' Music - 17 Cuts Of Music That Will Be Familiar To Those\n Who Have, Or Have Heard, Any Or All Of The Lone Ranger Mini-Episodes That\n Were Released By Decca Records In The Early And Mid-Fifties.  These Are Not\n Not The Actual Versions That Were Used, But Rather, For The Most Part, The Same\n Arrangements Of That Music.  Titles And Other Information Unknown."},
  "The+Lone+Ranger+-+Music+and+Miscellaneous+Items+and+Comedy+Bits2.mp3":{ "info":"The Lone Ranger Music CD Vol. 3 - Music and Miscellaneous Items and Comedy Bits\n The Lone Ranger Music – Plus Miscellaneous Items\n (track 2)\n 1.  Lone Ranger 'Decca' Music - continued.  16 more cuts of music.\n 2.  An excerpt from The Tonight Show with Johnny Carson's\n special guest Jay Silverheels (TV's Tonto)\n 3.  Lone Ranger comedy monologue by Jackie Vernon\n 4.  The Lone Psychiatrist - by Stan Freberg"},
  "The+Lone+Ranger+15th+Anniversary+Show_+The+Origins+Of+The+Lone+Ranger.mp3":{ "info":"The Lone Ranger Chronicles - The Lone Ranger 15th Anniversary Show 'Origin of the Lone Ranger' - Broadcast June 30, 1948"},
  "Abilene+Horse+Thieves.mp3":{ "info":"The Lone Ranger Chronicles - The Lone Ranger 15th Anniversary Show 'Abilene Horse Thieves' - Broadcast January 28, 1938"},
  "ABC+Radio+Network+-+John+F.+Kennedy+The+Gift+Outright+narrator+Fred+Foy+-+11_23_1964.mp3":{ "info":"ABC Radio Network - 'John F. Kennedy: The Gift Outright' - narrator Fred Foy - 11-23-1964"},
  "ABC+Radio+Network+-+Man+of+the+People+-+Harry+Truman+-+narrator+Fred+Foy.mp3":{ "info":"ABC Radio Network - 'Man of the People' - Harry Truman - narrator Fred Foy"},
  "ABC+Radio+Network+-+The+Day+The+President+Was+Shot+-+narrator+Fred+Foy+-+11_26_1963.mp3":{ "info":"ABC Radio Network - 'The Day The President Was Shot' - narrator Fred Foy - 11-26-1963"},
  "ABC+Radio+News+-+Crusader+From+Abilene+-+narrator+Fred+Foy+-10_00_1965.mp3":{ "info":"ABC Radio Network - 'Crusader From Abilene' - narrator Fred Foy - 10-01-1965"},
  "ABC+Radio+News+-+10_21_1970+-+Return+to+Studio+1-A.mp3":{ "info":"ABC Radio News - 10-21-1970 - Return to Studio 1-A - A colorful ABC Radio News flashback to Radio's Golden Age hosted by Don Gardiner with contributions from Fred Foy, George Ansbro, Tom O'Brien and Bob Prescott, among others.  The broadcast was done to mark the Golden Anniversary of American broadcasting."},
  "Fred+Flowerday+Interview+(Part+One)+-+with+Fred+Foy+Dick+Osgood+and+Hal+Neal+-+07_22_1971.mp3":{ "info":"Fred Flowerday Interview Part One with Fred Foy, Dick Osgood and Hal Neal - 07-22-1971"},
  "Fred+Flowerday+Interview+(Part+Two)+-+with+Fred+Foy+Dick+Osgood+and+Hal+Neal+-+07_22_1971.mp3":{ "info":"Fred Flowerday Interview Part Two with Fred Foy, Dick Osgood and Hal Neal - 07-22-1971"},
  "Fred+Flowerday+Interview+(Part+Three)+-+with+Fred+Foy+Dick+Osgood+and+Hal+Neal+-+07_22_1971.mp3":{ "info":"Fred Flowerday Interview Part Three with Fred Foy, Dick Osgood and Hal Neal - 07-22-1971"},
  "Sounds+of+Yesterday+-+My+Attempt+at+a+Bit+of+Nostalgia.mp3":{ "info":"'Sounds of Yesterday' - My Attempt At A Bit Of Nostalgia - Fred Foy"},
  "Tribute+to+Brace+-+Fred+Foy+-+Oxford+MI+-+Memorial+Centennial+Park+-+01_18-01_24.mp3":{ "info":"'Tribute to Brace' - Fred Foy - Oxford, MI - Memorial Centennial Park - 01-18 - 01-24"},
  "Tribute+to+Brace+Beemer.mp3":{ "info":"Tribute to Brace Beemer"},
  "Tribute+to+Brace+Beemer.mp4":{ "info":"Tribute to Brace Beemer"},
  "Orson+Welles+trying+to+make+a+commercial.mp3":{ "info":"Orson Welles making a commercial"},
  "Dad+Talks+About+His+Commercials.mp3":{ "info":"Dad talks about his commercials"},
  "Fred+Foy+Commercials+(Part+One).mp3":{ "info":"Fred Foy Trailers and Commercials Part 1 (Dad talks about these commercials) - Doctor Zhivago, Desert Flower Perfume, Michelob Beer, Metrical, None But The Brave, Gaines Burgers, Advice to the Lovelorn, Barney's, ABC Network Tribute to JFK."},
  "Fred+Foy+Commercials+(Part+Two).mp3":{ "info":"Fred Foy Trailers and Commercials Part 2 (abbreviated versions) - Doctor Zhivago, Playtex Toothbrush, Metrical, None But The Brave, Desert Flower Perfume, Barney's, The Outrage, Advice to the Lovelorn, ABC Network Tribute to JFK, Operation Crossbow"},
  "Donnie+Pitchford+-+Carthage+High+School+-+CHS+-+Fred+Foy+Telephone+Interview+(Part+One).mp3":{ "info":"Donnie Pitchford - KZQXLP - Carthage High School - CHS TV - 'The Golden Age of Radio' - A special tribute to Fred Foy and The Lone Ranger - Fred Foy Telephone Interview Part One - 08/21/2005"},
  "Donnie+Pitchford+-+Carthage+High+School+-+CHS+-+Fred+Foy+Telephone+Interview+(Part+Two).mp3":{ "info":"Donnie Pitchford - KZQXLP - Carthage High School - CHS TV - 'The Golden Age of Radio' - A special tribute to Fred Foy and The Lone Ranger - Fred Foy Telephone Interview Part Two - 08/21/2005"},
  "Donnie+Pitchford+-+Lum+and+Abner+Meet+The+Lone+Ranger+-+Fred+Foy+-+Jan.+2010.mp3":{ "info":"Donnie Pitchford - 'Lum and Abner Meet The Lone Ranger' - Fred Foy"},
  "Donnie+Pitchford+-+Final+CHS+TV+Edition+-+The+Golden+Age+of+Radio+-+Fred+Foy.mp3":{ "info":"Donnie Pitchford - Comedy and Adventure - Final CHS TV Edition - 'The Golden Age of Radio' - Fred Foy"},
  "Fred+Foy+on+Jack+Pattie+Show+with+Nolan+Kenner+-+Past+President+-+Kentucky+Broadcasters+Association+-+WVLK+Lexington+Kentucky+11_01_91.mp3":{ "info":"Fred Foy on Jack Pattie Show with Nolan Kenner - Past President - Kentucky Broadcaster's Association - WVLK Lexington, Kentucky 11/01/1991"},
  "Fred+Foy+Stars+as+Sergeant+Preston+of+the+Yukon+12_30_1954.mp3":{ "info":"Fred Foy Stars as Sergeant Preston of the Yukon in 'Deed to the Future'"},
  "The+Lone+Ranger+Jim+Millers+Return+11_14_1946+Fred+Foy+cast+as+Jim+Miller.mp3":{ "info":"'The Lone Ranger - Jim Miller's Return - 11-14-1946 - Fred Foy cast as Jim Miller"},
  "A+Lone+Ranger+Transition+One.mp3":{ "info":"A Lone Ranger Transition One - 'Slow Freight' - March 28, 1941 - Program #493 - Earl W. Graser played the LR from APR/1933 until the day he died: APR-8-1941.  The following four audio CD's show the transition from Graser to Brace Beemer, the shows announcer for the past several years…  Note the story line after Beemer took over on APR/9th with the LR being seriously injured and unable to speak…  By the APR-18th program, the LR was fully recovered & back in the saddle.. (Brace Beemer is the announcer - Earl W. Graser as the Lone Ranger) Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "A+Lone+Ranger+Transition+Two.mp3":{ "info":"A Lone Ranger Transition Two - 'Trap For A Safe Robber' - March 31, 1941 - Program #494 - Earl W. Graser played the LR from APR/1933 until the day he died: APR-8-1941.  Note the story line after Beemer took over on APR/9th with the LR being seriously injured and unable to speak…  By the APR-18th program, the LR was fully recovered & back in the saddle.. (Brace Beemer is the announcer - Earl W. Graser as the Lone Ranger) Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "A+Lone+Ranger+Transition+Three.mp3":{ "info":"A Lone Ranger Transition Three - 'Buyers Beware' - April 2, 1941 - Program #495 - Earl W. Graser played the LR from APR/1933 until the day he died: APR-8-1941.  Note the story line after Beemer took over on APR/9th with the LR being seriously injured and unable to speak…  By the APR-18th program, the LR was fully recovered & back in the saddle.. (Brace Beemer is the announcer - Earl W. Graser as the Lone Ranger) Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "A+Lone+Ranger+Transition+Four.mp3":{ "info":"A Lone Ranger Transition Four - 'Land Grabber's Loss' - April 4, 1941 - Program #496 - Earl W. Graser played the LR from APR/1933 until the day he died: APR-8-1941. Note the story line after Beemer took over on APR/9th with the LR being seriously injured and unable to speak…  By the APR-18th program, the LR was fully recovered & back in the saddle.. (Brace Beemer is the announcer - Earl W. Graser as the Lone Ranger) Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "A+Lone+Ranger+Transition+Five.mp3":{ "info":"A Lone Ranger Transition Five  - 'Sixty Days For Life' - April 7, 1941 - Program #497 - Earl W. Graser played the LR from APR/1933 until the day he died: APR-8-1941.  Note the story line after Beemer took over on APR/9th with the LR being seriously injured and unable to speak…  By the APR-18th program, the LR was fully recovered & back in the saddle.. (Brace Beemer is the announcer - Earl W. Graser as the Lone Ranger) - EARL W. GRASER WAS KILLED IN AN AUTO ACCIDENT ON APRIL 8TH… BRACE BEEMER TAKES OVER AS THE LR Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "A+Lone+Ranger+Transition+Six.mp3":{ "info":"A Lone Ranger Transition Six - 'Homesteader's Ruse' - April 9, 1941 - Program #498 - Earl W. Graser played the LR from APR/1933 until the day he died: APR-8-1941.  Note the story line after Beemer took over on APR/9th with the LR being seriously injured and unable to speak…  By the APR-18th program, the LR was fully recovered & back in the saddle.. (Brace Beemer is the Lone Ranger) - EARL W. GRASER WAS KILLED IN AN AUTO ACCIDENT ON APRIL 8TH… BRACE BEEMER TAKES OVER AS THE LR Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "A+Lone+Ranger+Transition+Seven.mp3":{ "info":"A Lone Ranger Transition Seven - 'Ghost Town' - April 11, 1941 - Program #499 - Earl W. Graser played the LR from APR/1933 until the day he died: APR-8-1941.  Note the story line after Beemer took over on APR/9th with the LR being seriously injured and unable to speak…  By the APR-18th program, the LR was fully recovered & back in the saddle.. (Brace Beemer as the Lone Ranger) Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "A+Lone+Ranger+Transition+Eight.mp3":{ "info":"A Lone Ranger Transition Eight - 'Work And Win' - April 14, 1941 - Program #500 - Earl W. Graser played the LR from APR/1933 until the day he died: APR-8-1941.  Note the story line after Beemer took over on APR/9th with the LR being seriously injured and unable to speak…  By the APR-18th program, the LR was fully recovered & back in the saddle.. (Brace Beemer as the Lone Ranger) Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "A+Lone+Ranger+Transition+Nine.mp3":{ "info":"A Lone Ranger Transition Nine - 'United We Stand' - April 16, 1941 - Program #501 - Earl W. Graser played the LR from APR/1933 until the day he died: APR-8-1941.  Note the story line after Beemer took over on APR/9th with the LR being seriously injured and unable to speak…  By the APR-18th program, the LR was fully recovered & back in the saddle.. (Brace Beemer as the Lone Ranger) Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "A+Lone+Ranger+Transition+Ten.mp3":{ "info":"A Lone Ranger Transition Ten - 'The Lone Ranger Moves' - April 18, 1941 - Program #502 - Earl W. Graser played the LR from APR/1933 until the day he died: APR-8-1941.  Note the story line after Beemer took over on APR/9th with the LR being seriously injured and unable to speak…  By the APR-18th program, the LR was fully recovered & back in the saddle.. (Brace Beemer as the Lone Ranger) Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "A+Lone+Ranger+Transition+Eleven.mp3":{ "info":"A Lone Ranger Transition Eleven - 'The Frontier Day Race' - June 28, 1948 - Program #2409/1634 - (Brace Beemer as the Lone Ranger) Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "A+Lone+Ranger+Transition+Twelve.mp3":{ "info":"A Lone Ranger Transition Twelve - The Origin of the Lone Ranger' - June 30, 1948 - Program #2410/1635 - (Brace Beemer as the Lone Ranger) Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!  AN ANNOUNCER TRANSITION - Fred Foy takes over as the announcer on July 2, 1948 and continues to the end of the live broadcasts on September 3, 1954"},
  "A+Lone+Ranger+Announcer+Transition+Thirteen.mp3":{ "info":"A Lone Ranger Announcer Transition Thirteen - 'The Meadow Sisters' - July 2, 1948 - Program #2411/1636 - Fred Foy takes over as the announcer/narrator of The Lone Ranger  Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "A+Lone+Ranger+Announcer+Transition+Fourteen.mp3":{ "info":"A Lone Ranger Announcer Transition Fourteen - 'Lone Ranger's Rescue' - July 5, 1948 - Program #2412/1637 - Fred Foy as announcer/narrator of The Lone Ranger  Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "A+Lone+Ranger+Announcer+Transition+Fifteen.mp3":{ "info":"A Lone Ranger Announcer Transition Fifteen - 'Deserted Mine Shaft' - July 7, 1948 - Program #2413/1638 - Fred Foy as announcer/narrator of The Lone Ranger  Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "A+Lone+Ranger+Announcer+Transition+Sixteen.mp3":{ "info":"A Lone Ranger Announcer Transition Sixteen - 'Old Cheyenne' - July 9, 1948 - Program #2414/1639 - Fred Foy as announcer/narrator of The Lone Ranger  Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "A+Lone+Ranger+Announcer+Transition+Seventeen.mp3":{ "info":"A Lone Ranger Announcer Transition Seventeen - 'Held On Suspicion' - July 12, 1948 - Program #2415/1640 - Fred Foy as announcer/narrator of The Lone Ranger  Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "A+Lone+Ranger+Announcer+Transition+Eighteen.mp3":{ "info":"A Lone Ranger Announcer Transition Eighteen - 'Young Doctor Lowell' - July 14, 1948 - Program #2416/1641 - Fred Foy as announcer/narrator of The Lone Ranger  Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "A+Lone+Ranger+Announcer+Transition+Nineteen.mp3":{ "info":"A Lone Ranger Announcer Transition Nineteen - 'Cattle Drive' - July 16, 1948 - Program #2417/1642 - Fred Foy as announcer/narrator of The Lone Ranger  Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "A+Lone+Ranger+Announcer+Transition+Twenty.mp3":{ "info":"A Lone Ranger Announcer Transition Twenty - 'A Bridge For A Bridge' - July 19, 1948 - Program #2418/1643 - Fred Foy as announcer/narrator of The Lone Ranger  Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "A+Lone+Ranger+Announcer+Transition+Twenty+One.mp3":{ "info":"A Lone Ranger Announcer Transition Twenty One - 'Arizona Pete' - July 21, 1948 - Program #2419/1644 - Fred Foy as announcer/narrator of The Lone Ranger  Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "A+Lone+Ranger+Announcer+Transition+Twenty+Two.mp3":{ "info":"A Lone Ranger Announcer Transition Twenty Two - 'Flashlight Ring No. 2' - November 3, 1948 - Program #2464/1689 - Fred Foy as announcer/narrator of The Lone Ranger  Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!\n On March 29, 1954 Brace Beemer could not perform as The Lone Ranger, so Fred Foy stepped into his role..."},
  "Decca+Records+-+The+Origin+of+The+Lone+Ranger.mp3":{ "info":"Decca Records 1951 - 'The Origin of The Lone Ranger' Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "Decca+Records+-+The+Origin+of+Silver+-+The+story+of+the+Lone+Ranger+and+his+great+horse+Silver.mp3":{ "info":"Decca Records 1951 - 'The Origin of Silver' -  The story of the Lone Ranger and his great horse Silver - Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "Decca+Records+-+The+Legend+of+Dan+Reid+-+The+story+of+how+the+Lone+Ranger+found+his+only+living+relative.mp3":{ "info":"Decca Records 1951 - 'The Legend of Dan Reid' - The story of how The Lone Ranger found his only living relative. Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "Decca+Records+-+The+Legend+of+The+Lone+Ranger+and+The+Colonels+Son.mp3":{ "info":"Decca Records 1951 - 'The Legend of The Lone Ranger and The Colonel's Son' Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "Decca+Records+-+The+Adventure+of+the+Lone+Ranger+and+The+War+Horse.mp3":{ "info":"Decca Records 1951 - 'The Adventure of The Lone Ranger and The War Horse' Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "Decca+Records+-+The+Adventure+of+the+Lone+Ranger+and+The+Booneville+Gold.mp3":{ "info":"Decca Records 1951 - 'The Adventure of The Lone Ranger and The Booneville Gold' Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "Decca+Records+-+Coming+of+Age+03_23_1938.mp3":{ "info":"Decca Records 1951 - 'Coming Of Age' - March 23, 1938 #804/29 Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "To+Justin+Xmas+1992+From+Pa.mp3":{ "info":"To Justin Xmas 1992 From Pa"},
  "01+The+Green+Hornet.mp3":{ "info":"A REVELATION… Dan Reid, who became prosperous in newspaper publishing, had a son, Britt Reid.. Britt took over as editor of The Daily Sentinel and fought crime in the style of his great-uncle, The Lone Ranger..  On NOV-4-1947, Dan finds out that his son is The Green Hornet.. Dan Reid is played by John Todd!!\n The Green Hornet - 'Graft Crosses A Bridge' - Nov-4-1947 - #832/492 - Jack McCarthy as The Green Hornet - Michael Tolin as Kato - Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "02+The+Green+Hornet.mp3":{ "info":"The Green Hornet - 'Too Hot To Handle' - Nov-11-1947 - #832/493 - Jack McCarthy as The Green Hornet - Michael Tolin as Kato - Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "Justin+and+Pa+Recording+(Lone+Ranger+Recreation).mp3":{ "info":"Justin and Pa Recording - Lone Ranger Recreation"},
  "Justin+At+The+Piano+(with+his+Pa).mp3":{ "info":"Justin and Pa Recording - Justin At The Piano"},
  "J+Von+Cutietta+(with+his+Pa)+(Lone+Ranger+Recreation).mp3":{ "info":"Justin and Pa Recording - J. Von Cutietta - Lone Ranger Recreation"},
  "Backstage+Boston+1989.mp3":{ "info":"Backstage Boston - Lyndsey Parker interviews Fred Foy on WRCA Waltham/Boston 1989 - Very good - WMBC section"},
  "Lone+Ranger+Silver+Spurs+05-21-1948.mp3":{ "info":"The Lone Ranger 'Silver Spurs' 05-21-1948"},
  "Lone+Ranger+Panchos+Double+07-30-1948.mp3":{ "info":"The Lone Ranger 'Pancho's Double' 07-30-1948"},
  "William+Tell+Overture+and+Origin.mp3":{ "info":"William Tell Overture - Origin Scene"},
  "William+Tell+Overture+Origin+Scene+Dads+Tape+4+min.mp3":{ "info":"William Tell Overture - Origin Scene - Dad's Tape - 4 minutes"},
  "Lone+Ranger+on+Decca+Records+Dads+Tapes.mp3":{ "info":"Lone Ranger on Decca Records Dad's Tapes - 1. Becomes The Lone Ranger - 2. Finds Silver - 3. Finds Dan Reid - 4. L.R. and The Colonel's Son"},
  "RETURN+OF+CAVENDISH+-+JAN-30-1953.mp3":{ "info":"RETURN OF CAVENDISH - The Lone Ranger 20th Anniversary Program - #3128 - 01-30-1953 - Fred Foy with a supporting cast - Creatively put together for me by Glenn Mueller with great original CD covers.  Thanks Glenn!!"},
  "Juggler+of+Notre+Dame.mp3":{ "info":"The Juggler of Notre Dame - Nelson Eddy and Ronald Coleman"},
  "Meanwhile.mp3":{ "info":"'Meanwhile Back At The Ranch…' - Fred Foy Remembers - A Fond Recollection"},
  "Radio+Classics+Live+VI+1995.mp4":{ "info":"Radio Classics Live VI Complete 1995 - Massasoit Community College - 04-07-1995 - Jerry Williams - Fred Foy performs in Challenge of the Yukon"},
  "Radio+Classics+Live+VI+1995+Trimmed.mp4":{ "info":"Radio Classics Live VI 1995 - Massasoit Community College - 04-07-1995 - Fred Foy performs in Challenge of the Yukon"},
  "Radio+Classics+Live+VI+1995+Yukon.mp4":{ "info":"Radio Classics Live VI 1995 - Massasoit Community College - 04-07-1995 - Fred Foy performs in Challenge of the Yukon"},
  "Radio+Classics+Live+VII+Complete+03_29_1996.mp4":{ "info":"Radio Classics Live VII Complete 1996 - Massasoit Community College - 03-29-1996"},
  "Radio+Classics+Live+VII+1996.mp4":{ "info":"Radio Classics Live VII 1996 - Massasoit Community College - 03-29-1996 - Fred Foy performs with his grandson Justin Cutietta"},
  "Radio+Classics+Live+VII+Home+Video+Rehearsals+03_29_1996.mp4":{ "info":"Radio Classics Live VII 1996 - Massasoit Community College - 03-29-1996 - Home Video Rehearsals"},
  "Radio+Classics+Live+VII+Excerpt+Home+Video+03_29_1996+Justin+and+Pa.mp4":{ "info":"Radio Classics Live VII 1996 - Massasoit Community College - 03-29-1996 - Home Video Excerpt"},
  "Radio+Classics+Live+VII+Justin+as+Dan+Reid+1996.mp4":{ "info":"Radio Classics Live VII Complete 1996 - Massasoit Community College - 03-29-1996 - Justin Cutietta as Dan Reid"},
  "Radio+Classics+Live+VIII+1997.mp4":{ "info":"Radio Classics Live VIII Complete 1997 - Massasoit Community College - 05-02-1997 - Dad gets an award!"},
  "Radio+Classics+Live+IX+Dad+Wendy+and+Justin.mp4":{"info":"Radio Classics Live IX 1998 - Massasoit Community College - Fred Foy performs with his daughter Wendy Foy and his grandson Justin Cutietta"},
  "Radio+Classics+Live+X+Complete+05_07_1999.mp4":{ "info":"Radio Classics Live X Complete 1999 - Massasoit Community College - 05-07-1999 - Fred Foy is introduced by his grandson Justin Cutietta"},
  "Radio+Classics+Live+XI+Complete+05_05_2000.mp4":{ "info":"Radio Classics Live XI Complete 2000 - Massasoit Community College - 05-05-2000 - Fred Foy performs with his grandson Justin Cutietta"},
  "Radio+Classics+Live+XI+Excerpt+from+Home+Movie+05_05_2000.mp4":{ "info":"Radio Classics Live XI Excerpt from Home Movie 2000 - Massasoit College - 05-05-2000"},
  "Radio+Classics+Live+XV+Home+Movie+2004.mp4":{ "info":"Radio Classics Live XV Home Video 2004 - Massasoit Community College - Fred Foy joined by his grandson Justin Cutietta plays the part of the Lone Ranger in a recreation of 'The Origin of the Lone Ranger'."},
  "Radio+Classics+Live+XV+Excerpt+2004.mp4":{ "info":"Radio Classics Live XV Home Video 2004 - Massasoit Community College - Excerpt from Home Video"},
  "Game+Shows.mp4":{ "info":"Game Shows - The Generation Gap (02-14-1969), $20,000 Pyramid (1978), To Tell The Truth (guest Fran Striker)"},
  "Games.mp4":{ "info":"Games - Clipped version"},
  "Hollywood+Close-Up.mp4":{ "info":"'Hollywood Close-Up' with host Harold Greene - May 14, 1983 - 'The Lone Ranger 50th Anniversary' - Fred Foy"},
  "Fred+Foy+FOTR+1993+Award.mp4":{ "info":"Hall of Fame - October 1993 - FOTR - Fred Foy - Introduced by George Ansbro"},
  "First+NBHF+1977+Freehold,+NJ.mp4":{ "info":"First National Broadcaster's Hall of Fame Ceremony - Fred Foy - founded by Art Schreiber - Freehold, NJ - 1977"},
  "Second+NBHF+1978+Freehold,+NJ+Hall+of+Fame.mp4":{ "info":"Second National Broadcaster's Hall of Fame Ceremony - Fred Foy - Art Schreiber - Freehold, NJ - 1978 - edited copy"},
  "Remember+When+Trimmed.mp4":{ "info":"Remember When - 1981 - 'On The Air' - 'The Story of Radio in America' - Hosted by Dick Cavett.  A fond remembrance of the people and events that have combined to become the story of America."},
  "Lone+Ranger+Day+-+Brace+Beemer+Memorial+Park+-+Mt.+Carmel+-+2004.mp4":{ "info":"Lone Ranger Day - Brace Beemer Memorial Park - Mount Carmel - 2004"},
  "Golden+Boot+Tape+1.mp4":{ "info":"Golden Boot Awards - Part One - 2004 - Dad accepting his award"},
  "Golden+Boot+Tape+2.mp4":{ "info":"Golden Boot Awards - Part Two - 2004"},
  "Dad+Accepting+Golden+Boot.mp4":{ "info":"Golden Boot Awards - 2004 - Excerpt Dad accepting his award"},
  "Family+Reagan+Library.mp4":{ "info":"Golden Boot Awards - 2004 - Excerpt Home Video"},
  "100+Paintings.mp4":{ "info":"100 Paintings - Fred Foy - 11-08-1991"},
  "Dick+Cavett+Commercials+1973.mp4":{ "info":"Dick Cavett Commercials and Show - Miracle Milman Dishware - Domino Sugar - Pfeiffer Salad Dressings - Alpo Commercials - 1973"},
  "Lone+Pine+1993.mp4":{ "info":"Lone Ranger Tribute - Lone Pine, California - 1993 - Special Guests: Clayton Moore, John Hart and Fred Foy"},
  "NLAS+Convention+1999.mp4":{ "info":"NLAS (National Lum and Abner Society) Convention - 1999 -Donnie Pitchford Tape - Fred Foy: 'Omnibus' with Hal Holbrook (1981) Lone Ranger segment - The Lone Ranger - an American Legend with Doug Llewelyn (1981) - The NLAS (National Lum and Abner Society) Convention (raw Footage) (06-25-1999) See Donnie Pitchford letter"},
  "Terry+Interview+FOTR.mp4":{ "info":"Terry Salomonson Interview Fred Foy - Friends of Old Time Radio Convention"},
  "The+Voice+Behind+the+Lone+Ranger+-+Bruce+Glazer+1994.mp4":{ "info":"'The Voice Behind The Lone Ranger' - My Dad talks about his life and career in an interview taped in 1994 at his then home in Reading, MA - hosted by Bruce Glazer - A Mansfield Cable 8 Presentation."},
  "Guy+Lombardos+New+Years+Eve.mp4":{ "info":"Guy Lombardo's New Year's Eve 1967 with Nelson Eddy and Gale Sherwood - Announcer Fred Foy"},
  "Today+Show+Willard+Scott+1995.mp4":{ "info":"Willard Scott Tribute to Fred Foy - 03-15-1995 - Today Show"},
  "Chicago+Radio+Hall+of+Fame+Induction+Ceremony+1995.mp4":{ "info":"Chicago Radio Hall of Fame Induction Ceremony - 10-29-1995 - 75th Anniversary of Radio - Fred Foy"},
  "Eyewitness+News+Farewell+12_13_1985.mp4":{ "info":"Eyewitness News Farewell - Fred Foy - 12-13-1985"},
  "OTR+Convention+Cincinnati+1996+(Part+1).mp4":{ "info":"OTR Convention Cincinnati Part One 1996 - Fred Foy, Parley Baer, Peg Lynch"},
  "OTR+Convention+Cincinnati+1996+(Part+2).mp4":{ "info":"OTR Convention Cincinnati Part Two 1996 - Fred Foy, Parley Baer, Peg Lynch"},
  "Legend+Lone+Ranger+Trailer.mp4":{ "info":"The Legend of the Lone Ranger Trailer - 1981"},
  "Sunday+Morning+Milepost.mp4":{ "info":"Sunday Morning - 'Milepost' - 75th Anniversary The Lone Ranger - Charles Osgood"},
  "SPERDVAC+2008.mp4":{ "info":"SPERDVAC 2008 Convention - Saturday Evening Presentations - 05-03-2008 - The Byron Kane Memorial Award - Frank Bresee with Stan Freberg - The Jack Benny Program - Starring Eddie Carroll and Chuck McCann, Directed by Gregg Oppenheimer - The Lone Ranger - Starring Fred Foy & Jan Merlin, Directed by Frank Bresee - Dad accepts an award"},
  "SPERDVAC+2008+The+Lone+Ranger+Starring+Fred+Foy+Director+Frank+Bresee.mp4":{ "info":"SPERDVAC 2008 - The Lone Ranger starring Fred Foy - Directed by Frank Bresee"},
  "SPERDVAC+2008+PANEL3.mp4":{ "info":"SPERDVAC 2008 Convention Panel - Friday 05-02-2008 - The Lone Ranger Panel - Host: Dick Beals with Fred Foy and Casey Kasem"},
  "Remembering+Radio+-+Dave+Parker.mp4":{ "info":"'Remembering Radio' - Dave Parker"},
  "NBHF+Inductions+1993+-+Dad+accepts+award.mp4":{ "info":"National Broadcaster's Hall of Fame Inductions - 1993 - Fred Foy, Ezra Stone, Joe Franklin - Dad accepts an award"},
  "FOTR+30th+Anniversary+Highlights+1976-2004+(Part+One).mp4":{ "info":"FOTR (Friends of Old Time Radio) - 30th Anniversary Highlights 1976-2004 - Part 1"},
  "FOTR+30th+Anniversary+Highlights+1976+-+2004+(Part+Two).mp4":{ "info":"FOTR (Friends of Old Time Radio) - 30th Anniversary Highlights 1976-2004 - Part 2"},
  "FOTR+Gift.mp4":{ "info":"FOTR (Friends of Old Time Radio) Memories - Presented as a gift to FOTR and the convention attendees from Fred & Ellen Berney of Satellite Media Production"},
  "FOTR+Fred+Foy+and+Anthony+Tolin.mp4":{ "info":"FOTR (Friends of Old Time Radio) Fred Foy and Anthony Tolin - October 2003"},
  "Fred+Foy+Tribute.mp3":{ "info":"Tribute to Fred Foy - 2010 - Steve LeVeille Show WBZ Boston with Mel Simons"},
  "Freds+Scrapbook.mp4":{ "info":"Fred's Scrapbook"},
  "The+Making+of+Doctor+Zhivago.mp4":{ "info":"'The Making of Doctor Zhivago' - Announcer - Narrator - Fred Foy 1. Doctor Zhivago - Original Trailer  2. Zhivago - David Lean  3. Omar Sharif  4. Geraldine Chaplin  5. Moscow in Madrid - Location"},
  "Kentucky+Broadcasters+Association+-+1991.mp4":{ "info":"Kentucky Broadcasters Association - Nolan Kenner - Fred Foy Meritorious Service Award - October 1991"},
  "CAVETT+PERSPECTIVE+FINAL.mp3":{ "info":"Dick Cavett discusses his new book 'Talk Show' with Bill Diehl of ABC News"},
  "Missouri+Waltz.mp3":{ "info":"Hush-A-Bye Ma Baby - Elsie Baker"},
  "Jay+Silverheels.mp4":{ "info":"Jay Silverheels - The Man Beside the Mask"},
  "Fred+Foy+Recording+Radio+Spirits+When+Radio+Was+1995.mp4":{"info": "Fred Foy Recording Radio Spirits When Radio Was - 1995"},
  "Andy+Phone+Message.mp4":{"info": "Andy Southard's Phone Message"},
  "Dad+Amagansett+Door.mp4":{"info": "Dad in Amagansett"},
  "Dad+and+Betty.mp4":{"info": "Dad and Betty at Waverly Inn, New York City"},
  "Dads+Christmas+Card+1982.mp3":{"info": "Dad's Christmas Card to Nancy, Wendy and Fritz - 1982"},
  "Dads+Memorial+Part+1.mp4":{"info": "Dad's Memorial Service - Part One -  2/19/2011"},
  "Dads+Memorial+Service+Part+2A.mp4":{"info": "Dad's Memorial Service - Part Two - 2/19/2011"},
  "Davey.mp3":{"info": "Nancy Singing Davey Crockett"},
  "Fran+Striker.mp4":{"info": "Fran Striker on 'To tell the Truth'"},
  "Fred+Foy+The+Lone+Ranger+Creed.mp4":{"info": "Fred Foy The Lone Ranger Creed"},
  "Frere+Jacques.mp3":{"info": "Wendy singing Frere Jacques and Jingle Bells - 1958"},
  "Mom+and+Dad+1950.mp4":{"info": "Mom and Dad - 1950"},
  "Repertoire.mp3":{"info": "Wendy's Repertoire"},
  "Someday.mp3":{"info": "Nancy sings Someday My Prince Will Come"},
  "Dad+Nancy+Wendy+and+Fritz+1962.mp3":{"info": "Dad, Nancy, Wendy and Fritz - 1962"},
  "Tribute+to+Fred+Foy+12_23_10+The+Steve+LeVeille+Show.mp3":{"info": "Tribute to Fred Foy - The Steve LeVeille Show - 12/23/10"},
  "Swinging+Birmingham+MI+1955.mp4":{"info": "Swinging in Birmingham MI. 1955"},
  "Seasonal+Turkey+Justin+and+Pa.mp4":{"info": "Seasonal Farms Turkey - Justin and Pa"},
  "Wendy+Nancy+Fritz+and+Dad+Making+Entrance+Amagansett.mp4":{"info": "Wendy, Nancy, Fritz and Dad Making an Entrance in Amagansett"},
  "Radio+Classics+Live+IX+Dad,+Wendy+and+Justin.mp4":{ "info":"Radio Classics Live IX 1998 - Massasoit Community College - Fred Foy, Wendy Foy and Justin Cutietta"},
  "Wild_West_Show_Demo_2001__.mp3":{ "info":"Bob Burnham UNCOVERED FROM MY STASH OF GREAT AUDIO! I produced and distributed this show -- 'The Wild West Show' -- for Montie Montana Jr., who was the son of the cowboy star. Montana Jr has also since passed. The show was heard on a handful of stations in the early 2000s and distributed by me. Yes, that is THE voice of Fred Foy, who did all the drops for every show, and voiced this demo which I also produced in my syndication studio. Mr Foy was a total delight to work with! There were VERY FEW second takes. He usually nailed them on the first read-thru! What a priveldge to work with such a legendary pro! I have a few episodes of the complete shows, but here's the demo. Enjoy!"},
  "6-01+Fred+Foy+-+John+Hart+01_09_2000.mp3":{ "info":"In memoriam\nCLAYTON MOORE\n1914-1999\nOn Sunday, January 9, 2000, Bill Bragg and\nYesterdayUSA spoke with the last living person to\nplay the LONE RANGER, John Hart, and with the\nannouncer whose voice was most associated with\nthe LONE RANGER during its run on radio and TV,\nFred Foy. The topic was memories of Clayton\n Moore who is best remembered for his portrayal of\nthe RANGER on TV.\n   With only very minor editing, here is that\n historic interview.\n    Incidentally, the opening and closing\n signatures that I have included are from the very last\nbroadcast radio episode, September 3, 1954."},
  "01+Sergeant+Preston+-+Land+Deal+01_27_1955.mp3":{ "info":"Sergeant Preston - Land Deal - 01/27/1955"},
  "02+Sergeant+Preston+-+Race+Against+Time+02_08_1955.mp3":{ "info":"Sergeant Preston - Race Against Time - 02/08/1955"},
  "01+All+Things+Considered+01_15_2008.mp3":{ "info":"All Things Considered - Fred Foy - NPR - In Character: The Lone Ranger - 01/15/2008"},
  "01+WNYC+Fred+Foy+Interview.mp3":{ "info":"WNYC - Fred Foy Interview - 10/18/2005"},
  "02+WOCM+Fred+Foy+Interview.mp3":{ "info":"WOCM - Fred Foy Interview - 10/19/2005"},
  "01+Philco+Radio+Time+Bing+Crosby+and+Brace+Beemer.mp3":{ "info":"Philco Radio Time - Bing Crosby and Brace Beemer - 01/07/1948"},
  "1960+Chevrolet+Corvair+Commercial.mp4":{"info":"Chevrolet Corvair Commercial - 1960"}
};
export default descriptions;
