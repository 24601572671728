import React, { Component } from 'react';

class Home extends Component {
	constructor(props) {
		super(props);
		this.store = this.props.store;
	}

	render() {
		return (
			<div className='flex-container'>
        <span className='welcome'>
        </span>
        <span className='fwfcover'>
        </span>
			</div>
		)
	}
}
export default (Home);