import React, { Component } from 'react';
import descriptions from './descriptions.js'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

export default class Audio extends Component {
	constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {s3Location: null};    
  }

  componentDidMount() {
    if (!this.state.s3Location){
      this.buildMenuItems();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.s3Location) {
      this.setState({s3Location:null});
    }
  }

  handleClick = (e) => {
    e.preventDefault();
    this.doNavigation(e.target.attributes.getNamedItem('data').value);
    const href = e.target.attributes.getNamedItem('href').value;
    history.push(href);
  };

  doNavigation = (location) => {
    this.setState({s3Location:location});
  }

  buildMenuItems = () => {
    var ul = document.createElement('ul');
    //divide map by 2 to determine left and right
    var splitMenu = this.audioMap.length / 2;
    var ulItem = document.getElementById('fredMenul').appendChild(ul);
    let bSplitMenu = false;
    this.audioMap.forEach((element, index) => {
      
      if(index >= splitMenu && !bSplitMenu){
        var ul = document.createElement('ul');
        ulItem = document.getElementById('fredMenur').appendChild(ul);
        bSplitMenu = true;
      }

      let li = document.createElement('li');
      let a = document.createElement("a");
      a.onclick = this.handleClick;
      a.href = element[0];
      a.innerHTML = element[1].display;
      a.setAttribute('data',element[1].data);
      li.append(a);
      ulItem.appendChild(li);
      let hr = document.createElement('hr')
      ulItem.appendChild(hr);
    })
  }

	render() {
    const isLocationSet = this.state.s3Location ? true : false;
    let showMenu = ''
    let showAudio = 'hideAudioDisplay'
    let audioFile = '';
    let description = '';
    if(isLocationSet){
      const location = this.state.s3Location;
      audioFile = '//s3.amazonaws.com/fredfoy.com/' + location;
      const descriptionKey = location.split('/')[1];
      description = descriptions[descriptionKey].info; 
      showMenu = 'hideMenuDisplay'
      showAudio = ''
    }
    return (
      <div>
        <div className={showAudio}>
          <div style={{'display':'flex', 'alignItems': 'center', 'justifyContent': 'center','marginTop':'48px','marginRight':'-10px'}} >
            <audio src={audioFile} controls/>
          </div>
          <div style={{'textAlign':'center'}}>{description}</div>
        </div>
        <div className={showMenu}>
          <div id='fredMenu1'>
            <div id='fredMenul' className='menu1l menux'></div>
            <div id='fredMenur' className='menu1r menux'></div>
          </div>
        </div>
      </div>
    )
  }

  audioMap = [
    ['/audio/A-Lone-Ranger-Transition-One-Slow-Freight-03/28/1941',{'data':'Audio/A+Lone+Ranger+Transition+One.mp3','display':'A Lone Ranger Transition One - Slow Freight - 03/28/1941'}],
    ['/audio/A-Lone-Ranger-Transition-Two-Trap-For-A-Safe-Robber-03/31/1941',{'data':'Audio/A+Lone+Ranger+Transition+Two.mp3','display':'A Lone Ranger Transition Two - Trap For A Safe Robber - 03/31/1941'}],
    ['/audio/A-Lone-Ranger-Transition-Three-Buyers-Beware-04/02/1941',{'data':'Audio/A+Lone+Ranger+Transition+Three.mp3','display':'A Lone Ranger Transition Three - Buyers Beware - 04/02/1941'}],
    ['/audio/A-Lone-Ranger-Transition-Four-Land-Grabbers-Loss-04/04/1941',{'data':'Audio/A+Lone+Ranger+Transition+Four.mp3','display':"A Lone Ranger Transition Four - Land Grabber's Loss - 04/04/1941"}],
    ['/audio/A-Lone-Ranger-Transition-Five-Sixty-Days-For-Life-04/07/1941',{'data':'Audio/A+Lone+Ranger+Transition+Five.mp3','display':'A Lone Ranger Transition Five - Sixty Days For Life - 04/07/1941'}],
    ['/audio/A-Lone-Ranger-Transition-Six-Homesteaders-Ruse-04/09/1941',{'data':'Audio/A+Lone+Ranger+Transition+Six.mp3','display':"A Lone Ranger Transition Six - Homesteader's Ruse - 04/09/1941"}],
    ['/audio/A-Lone-Ranger-Transition-Seven-Ghost-Town-04/11/1941',{'data':'Audio/A+Lone+Ranger+Transition+Seven.mp3','display':'A Lone Ranger Transition Seven - Ghost Town - 04/11/1941'}],
    ['/audio/A-Lone-Ranger-Transition-Eight-Work-And-Win-04/14/1941',{'data':'Audio/A+Lone+Ranger+Transition+Eight.mp3','display':'A Lone Ranger Transition Eight - Work And Win - 04/14/1941'}],
    ['/audio/A-Lone-Ranger-Transition-Nine-United-We-Stand-04/16/1941',{'data':'Audio/A+Lone+Ranger+Transition+Nine.mp3','display':'A Lone Ranger Transition Nine - United We Stand - 04/16/1941'}],
    ['/audio/A-Lone-Ranger-Transition-Ten-The-Lone-Ranger-Moves-04/18/1941',{'data':'Audio/A+Lone+Ranger+Transition+Ten.mp3','display':'A Lone Ranger Transition Ten - The Lone Ranger Moves - 04/18/1941'}],
    ['/audio/A-Lone-Ranger-Transition-Eleven-The-Frontier-Day-Race-06/28/1948',{'data':'Audio/A+Lone+Ranger+Transition+Eleven.mp3','display':'A Lone Ranger Transition Eleven - The Frontier Day Race - 06/28/1948'}],
    ['/audio/A-Lone-Ranger-Transition-Twelve-The-Origin-of-the-Lone Ranger-06/30/1948',{'data':'Audio/A+Lone+Ranger+Transition+Twelve.mp3','display':'A Lone Ranger Transition Twelve - The Origin of the Lone Ranger - 06/30/1948'}],
    ['/audio/A-Lone-Ranger-Transition-Thirteen-The-Meadow-Sisters-07/02/1948',{'data':'Audio/A+Lone+Ranger+Announcer+Transition+Thirteen.mp3','display':'A Lone Ranger Transition Thirteen - The Meadow Sisters - 07/02/1948'}],
    ['/audio/A-Lone-Ranger-Transition-Fourteen-Lone-Rangers-Rescue-07/05/1948',{'data':'Audio/A+Lone+Ranger+Announcer+Transition+Fourteen.mp3','display':"A Lone Ranger Transition Fourteen - Lone Ranger's Rescue - 07/05/1948"}],
    ['/audio/A-Lone-Ranger-Transition-Fifteen-Deserted-Mine-Shaft-07/07/1948',{'data':'Audio/A+Lone+Ranger+Announcer+Transition+Fifteen.mp3','display':'A Lone Ranger Transition Fifteen - Deserted Mine Shaft - 07/07/1948'}],
    ['/audio/A-Lone-Ranger-Transition-Sixteen-Old-Cheyenne-07/09/1948',{'data':'Audio/A+Lone+Ranger+Announcer+Transition+Sixteen.mp3','display':'A Lone Ranger Transition Sixteen - Old Cheyenne - 07/09/1948'}],
    ['/audio/A-Lone-Ranger-Transition-Seventeen-Old-Cheyenne-07/09/1948',{'data':'Audio/A+Lone+Ranger+Announcer+Transition+Seventeen.mp3','display':'A Lone Ranger Transition Seventeen - Held On Suspicion - 07/12/1948'}],
    ['/audio/A-Lone-Ranger-Transition-Eighteen-Young-Doctor-Lowell-07/14/1948',{'data':'Audio/A+Lone+Ranger+Announcer+Transition+Eighteen.mp3','display':'A Lone Ranger Transition Eighteen - Young Doctor Lowell - 07/14/1948'}],
    ['/audio/A-Lone-Ranger-Transition-Nineteen-Cattle-Drive-07/16/1948',{'data':'Audio/A+Lone+Ranger+Announcer+Transition+Nineteen.mp3','display':'A Lone Ranger Transition Nineteen - Cattle Drive - 07/16/1948'}],
    ['/audio/A-Lone-Ranger-Transition-Twenty-A-Bridge-For-A-Bridge-07/19/1948',{'data':'Audio/A+Lone+Ranger+Announcer+Transition+Twenty.mp3','display':'A Lone Ranger Transition Twenty - A Bridge For A Bridge - 07/19/1948'}],
    ['/audio/A-Lone-Ranger-Transition-Twenty-One-Arizona-Pete-07/21/1948',{'data':'Audio/A+Lone+Ranger+Announcer+Transition+Twenty+One.mp3','display':'A Lone Ranger Transition Twenty One - Arizona Pete - 07/21/1948'}],
    ['/audio/A-Lone-Ranger-Transition-Twenty-Two-Flashlight-Ring No.-2-11/03/1948',{'data':'Audio/A+Lone+Ranger+Announcer+Transition+Twenty+Two.mp3','display':'A Lone Ranger Transition Twenty Two - Flashlight Ring No. 2 - 11/03/1948'}],
    ['/audio/A-Lone-Ranger-Opener-To-End-All-Lone-Ranger-Openers',{'data':'Audio/A+Lone+Ranger+Opener+To+End+All+Lone+Ranger+Openers+(Private+Recording).mp3','display':'A Lone Ranger Opener To End All Lone Ranger Openers'}],
    ['/audio/ABC-Radio-Network-John-F.-Kennedy-The-Gift-Outright-11/23/1964',{'data':'Audio/ABC+Radio+Network+-+John+F.+Kennedy+The+Gift+Outright+narrator+Fred+Foy+-+11_23_1964.mp3','display':'ABC Radio Network - John F. Kennedy The Gift Outright - 11/23/1964'}],
    ['/audio/ABC-Radio-Network-Man-of-the-People-Harry Truman',{'data':'Audio/ABC+Radio+Network+-+Man+of+the+People+-+Harry+Truman+-+narrator+Fred+Foy.mp3','display':'ABC Radio Network - Man of the People - Harry Truman'}],
    ['/audio/ABC-Radio-Network-The-Day-The-President-Was-Shot-11/26/1963',{'data':'Audio/ABC+Radio+Network+-+The+Day+The+President+Was+Shot+-+narrator+Fred+Foy+-+11_26_1963.mp3','display':'ABC Radio Network - The Day The President Was Shot - 11/26/1963'}],
    ['/audio/ABC-Radio-News-Crusader-From-Abilene-10/01/1965',{'data':'Audio/ABC+Radio+News+-+Crusader+From+Abilene+-+narrator+Fred+Foy+-10_00_1965.mp3','display':'ABC Radio News - Crusader From Abilene - 10/01/1965'}],
    ['/audio/ABC-Radio-News-Return-to-Studio-1A-10/21/1970',{'data':'Audio/ABC+Radio+News+-+10_21_1970+-+Return+to+Studio+1-A.mp3','display':'ABC Radio News - Return to Studio 1A - 10/21/1970'}],
    ['/audio/All-Things-Considered-Fred-Foy-NPR-01/15/2008',{'data':'Audio/01+All+Things+Considered+01_15_2008.mp3','display':'All Things Considered - Fred Foy-NPR-01/15/2008'}],
    ['/audio/Andy-Southard-20th-Anniv-LR-Show-01/30/1953',{'data':'Audio/Andy+Southard+20th+Anniv+LR+Show+01301953.mp3','display':'Andy Southard 20th Anniv LR Show - 01/30/1953'}],
    ['/audio/Andy-Southard-Final-LR Show-05/27/1955',{'data':'Audio/Andy+Southard+Final+LR+Show+05-27-1955.mp3','display':'Andy Southard Final LR Show - 05/27/1955'}],
    ['/audio/Andy-Southard-Original-Who-Was-That-Masked-Man-Montage',{'data':'Audio/Andy+Southard+Original+Who+Was+That+Masked+Man+Montage.mp3','display':'Andy Southard Original Who Was That Masked Man Montage'}],
    ['/audio/Backstage-Boston-1989',{'data':'Audio/Backstage+Boston+1989.mp3','display':'Backstage Boston - 1989'}],
    ['/audio/Bing-Crosby-Collection-Gather-Lilacs',{'data':'Audio/Bing+Crosby+Collection+Gather+Lilacs.mp3','display':'Bing Crosby Collection Gather Lilacs'}],
    ['/audio/Bobb-Lynes-Interview-with-Fred Foy-The-Old-Time-Radio-Show-10/12/1985',{'data':'Audio/01+Bobb+Lynes+Interview.mp3','display':'Bobb Lynes Interview with Fred Foy - The Old Time Radio Show - 10/12/1985'}],
    ['/audio/Bobb-Lynes-OTRC-KCRW-10/20/1985',{'data':'Audio/Bobb+Lynes+OTRC+KCRW+10201985.mp3','display':'Bobb Lynes OTRC KCRW - 10/20/1985'}],
    ['/audio/Bob-Mest-Hello-Lois!',{'data':'Audio/Bob+Mest+Hello+Lois!+with+Fred+Foy+and+James+E.+Naylor.mp3','display':'Bob Mest Hello Lois!'}],
    ['/audio/Cavett-Perspective-Dick-Cavett-Interview-Bill-Diehl-ABC-News',{'data':'Audio/CAVETT+PERSPECTIVE+FINAL.mp3','display':'Cavett Perspective - Dick Cavett Interview - Bill Diehl - ABC News'}],
    ['/audio/Decca-Records-The-Origin-of-The-Lone-Ranger-Fred Foy',{'data':'Audio/The+Origin+of+The+Lone+Ranger+-+Decca+Records.mp3','display':'Decca Records - The Origin of The Lone Ranger - Fred Foy'}],
    ['/audio/Decca-Records-The-Origin-of-Silver',{'data':'Audio/Decca+Records+-+The+Origin+of+Silver+-+The+story+of+the+Lone+Ranger+and+his+great+horse+Silver.mp3','display':'Decca Records - The Origin of Silver'}],
    ['/audio/Decca-Records-The-Legend-of-Dan-Reid',{'data':'Audio/Decca+Records+-+The+Legend+of+Dan+Reid+-+The+story+of+how+the+Lone+Ranger+found+his+only+living+relative.mp3','display':'Decca Records - The Legend of Dan Reid'}],
    ['/audio/Decca-Records-The-Legend-of-The-Lone-Ranger-and-The-Colonels-Son',{'data':'Audio/Decca+Records+-+The+Legend+of+The+Lone+Ranger+and+The+Colonels+Son.mp3','display':"Decca Records - The Legend of The Lone Ranger and The Colonel's Son"}],
    ['/audio/Decca-Records-The-Adventure-of-the-Lone-Ranger-and-The-War-Horse',{'data':'Audio/Decca+Records+-+The+Adventure+of+the+Lone+Ranger+and+The+War+Horse.mp3','display':'Decca Records - The Adventure of the Lone Ranger and The War Horse'}],
    ['/audio/Decca-Records-The-Adventure-of-the-Lone-Ranger-and-The-Booneville-Gold',{'data':'Audio/Decca+Records+-+The+Adventure+of+the+Lone+Ranger+and+The+Booneville+Gold.mp3','display':'Decca Records - The Adventure of the Lone Ranger and The Booneville Gold'}],
    ['/audio/Decca-Records-Coming of Age-03/23/1938',{'data':'Audio/Decca+Records+-+Coming+of+Age+03_23_1938.mp3','display':'Decca Records - Coming of Age - 03/23/1938'}],
    ['/audio/Detroit-Producers-Association-Pioneer-Radio-Night',{'data':'Audio/Detroit+Producers+Assoc+Pioneer+Radio+Night+Full+12061979.mp3','display':'Detroit Producers Association - Pioneer Radio Night'}],
    ['/audio/Decca-Records-Dads-Tapes',{'data':'Audio/Lone+Ranger+on+Decca+Records+Dads+Tapes.mp3','display':"Decca Records - Dad's Tapes"}],
    ['/audio/Donnie-Pitchford-Carthage-High-School-CHS-Telephone-Interview-Part One',{'data':'Audio/Donnie+Pitchford+-+Carthage+High+School+-+CHS+-+Fred+Foy+Telephone+Interview+(Part+One).mp3','display':'Donnie Pitchford - Carthage High School - CHS - Telephone Interview - Part One'}],
    ['/audio/Donnie-Pitchford-Carthage-High-School-CHS-Telephone-Interview-Part Two',{'data':'Audio/Donnie+Pitchford+-+Carthage+High+School+-+CHS+-+Fred+Foy+Telephone+Interview+(Part+Two).mp3','display':'Donnie Pitchford - Carthage High School - CHS - Telephone Interview - Part Two'}],
    ['/audio/Donnie-Pitchford-Final-CHS-TV-Edition-The-Golden-Age-of-Radio-Fred Foy',{'data':'Audio/Donnie+Pitchford+-+Final+CHS+TV+Edition+-+The+Golden+Age+of+Radio+-+Fred+Foy.mp3','display':'Donnie Pitchford - Final CHS TV Edition - The Golden Age of Radio - Fred Foy'}],
    ['/audio/Donnie-Pitchford-Lum-and-Abner',{'data':'Audio/Lum+and+Abner.mp3','display':'Donnie Pitchford - Lum and Abner'}],
    ['/audio/Donnie-Pitchford-Lum-and-Abner-Meet-The-Lone-Ranger-Fred Foy-Jan.-2010',{'data':'Audio/Donnie+Pitchford+-+Lum+and+Abner+Meet+The+Lone+Ranger+-+Fred+Foy+-+Jan.+2010.mp3','display':'Donnie Pitchford - Lum and Abner Meet The Lone Ranger - Fred Foy - Jan. 2010'}],
    ['/audio/Fred-Foy-Commercials-Part-One',{'data':'Audio/Fred+Foy+Commercials+(Part+One).mp3','display':'Fred Foy Commercials - Part One'}],
    ['/audio/Fred-Foy-Commercials-Part-Two',{'data':'Audio/Fred+Foy+Commercials+(Part+Two).mp3','display':'Fred Foy Commercials - Part Two'}],
    ['/audio/Fred-Foy-on-Jack-Pattie-Show-with-Nolan-Kenner',{'data':'Audio/Fred+Foy+on+Jack+Pattie+Show+with+Nolan+Kenner+-+Past+President+-+Kentucky+Broadcasters+Association+-+WVLK+Lexington+Kentucky+11_01_91.mp3','display':'Fred Foy on Jack Pattie Show with Nolan Kenner'}],
    ['/audio/Fred-Foy-Stars-as-Sergeant-Preston-of-the-Yukon',{'data':'Audio/Fred+Foy+Stars+as+Sergeant+Preston+of+the+Yukon+12_30_1954.mp3','display':'Fred Foy Stars as Sergeant Preston of the Yukon'}],
    ['/audio/Fred-Foy-Stars-as-The-Lone-Ranger-Burly-Scotts-Sacrifice-03/29/1954',{'data':'Audio/Fred+Foy+Stars+as+The+Lone+Ranger+Burley+Scotts+Sacrifice+03_29_1954.mp3','display':"Fred Foy Stars as The Lone Ranger - Burly Scott's Sacrifice - 03/29/1954"}],
    ['/audio/Fred-Foy-Tribute',{'data':'Audio/Fred+Foy+Tribute.mp3','display':'Fred Foy Tribute'}],
    ['/audio/Fred-Foy-John-Hart-Bill-Bragg-and-YesterdayUSA-01/09/2000',{'data':'Audio/6-01+Fred+Foy+-+John+Hart+01_09_2000.mp3','display':'Fred Foy - John Hart - Bill Bragg and YesterdayUSA - 01/09/2000'}],
    ['/audio/Fred-Flowerday-Interview-Part-One-07/22/1971',{'data':'Audio/Fred+Flowerday+Interview+(Part+One)+-+with+Fred+Foy+Dick+Osgood+and+Hal+Neal+-+07_22_1971.mp3','display':'Fred Flowerday Interview - Part One - 07/22/1971'}],
    ['/audio/Fred-Flowerday-Interview-Part-Two-07/22/1971',{'data':'Audio/Fred+Flowerday+Interview+(Part+Two)+-+with+Fred+Foy+Dick+Osgood+and+Hal+Neal+-+07_22_1971.mp3','display':'Fred Flowerday Interview - Part Two - 07/22/1971'}],
    ['/audio/Fred-Flowerday-Interview-Part-Three-07/22/1971',{'data':'Audio/Fred+Flowerday+Interview+(Part+Three)+-+with+Fred+Foy+Dick+Osgood+and+Hal+Neal+-+07_22_1971.mp3','display':'Fred Flowerday Interview - Part Three - 07/22/1971'}],
    ['/audio/Golden-Days-of-Radio-Frank-Bresee',{'data':'Audio/Frank+Brisee+The+Golden+Days+of+Radio.mp3','display':'Golden Days of Radio - Frank Bresee'}],
    ['/audio/Golden-Days-of-Radio-Frank-Bresee-Guest-Fred-Foy-Part-One',{'data':'Audio/Golden+Days1.mp3','display':'Golden Days of Radio - Frank Bresee - Guest Fred Foy - Part One'}],
    ['/audio/Golden-Days-of-Radio-Frank-Bresee-Guest-Fred-Foy-Part-Two',{'data':'Audio/Golden+Days+2.mp3','display':'Golden Days of Radio - Frank Bresee - Guest Fred Foy - Part Two'}],
    ['/audio/Golden-Days-of-Radio-Frank-Bresee-Guest-Fred-Foy-Part-Three',{'data':'Audio/Golden+Days+3.mp3','display':'Golden Days of Radio - Frank Bresee - Guest Fred Foy - Part Three'}],
    ['/audio/Golden-Days-of-Radio-Frank-Bresee-Guest-Fred-Foy-Part-Four',{'data':'Audio/Golden+Days+4.mp3','display':'Golden Days of Radio - Frank Bresee - Guest Fred Foy - Part Four'}],
    ['/audio/Golden-Days-of-Radio-Frank-Bresee-Guest-Fred-Foy-Part-Five',{'data':'Audio/Golden+Days+5.mp3','display':'Golden Days of Radio - Frank Bresee - Guest Fred Foy - Part Five'}],
    ['/audio/Green-Hornet-and-Yukon Openings',{'data':'Audio/Green+Hornet+and+Yukon+Openings.mp3','display':'Green Hornet and Yukon Openings'}],
    ['/audio/Haunted-Eye-Fred-Foy-and-Jim-Harmon',{'data':'Audio/Haunted+Eye+Fred+Foy+and+Jim+Harmon.mp3','display':'Haunted Eye - Fred Foy and Jim Harmon'}],
    ['/audio/Intrigue-Dawn-Over-the-Amazon-07/31/1946',{'data':'Audio/Jack+Benny+Cairo+Intrigue+Dads+Tape2.mp3','display':'Intrigue - Dawn Over the Amazon - 07/31/1946'}],
    ['/audio/Jack-Benny-Cairo,-Egypt-1943',{'data':'Audio/Jack+Benny2+2733+minutes.mp3','display':'Jack Benny - Cairo, Egypt - 1943'}],
    ['/audio/Jordan-Rich-Tribute-to-Fred-Foy',{'data':'Audio/Jordan+Rich+Tribute+to+Fred+Foy.mp3','display':'Jordan Rich Tribute to Fred Foy'}],
    ['/audio/Juggler-of-Notre-Dame',{'data':'Audio/Juggler+of+Notre+Dame.mp3','display':'Juggler of Notre Dame'}],
    ['/audio/KPBX-70th-Anniversary',{'data':'Audio/KPBX+70th+Anniversary+Fred+Foy.mp3','display':'KPBX 70th Anniversary'}],
    ['/audio/Ken-Meyer-Interview',{'data':'Audio/Ken+Meyer2.mp3','display':'Ken Meyer Interview'}],
    ['/audio/Ken-Meyer-Interview-10/03/1986',{'data':'Audio/Ken+Meyer+Interview+10-03-1986+Guest+Fred+Foy.mp3','display':'Ken Meyer Interview - 10/03/1986'}],
    ['/audio/Ken-Meyer-with-Fred-Foy-and-Clayton-Moore-WEEI-Boston',{'data':'Audio/Ken+Meyer+with+Fred+Foy+and+Clayton+Moore+WEEI+Boston.mp3','display':'Ken Meyer with Fred Foy and Clayton Moore WEEI Boston'}],
    ['/audio/MGM-Movie-Trailers-Promos',{'data':'Audio/Fred+Foy+Radio+Spots.mp3','display':'MGM Movie Trailers - Promos'}],
    ['/audio/Movie-Trailers-Integrated',{'data':'Audio/Trailers+to+Integrate.mp3','display':'Movie Trailers - Integrated'}],
    ['/audio/Meanwhile-Back-At-The-Ranch-Fred-Foy-Remembers',{'data':'Audio/Meanwhile.mp3','display':'Meanwhile Back At The Ranch - Fred Foy Remembers'}],
    ['/audio/Nostalgia-Scrapbook-Tribute-to-the-Lone-Ranger',{'data':'Audio/Nostalgia+Scrapbook+Tribute+to+the+Lone+Ranger.mp3','display':'Nostalgia Scrapbook Tribute to the Lone Ranger'}],
    ['/audio/Orson-Welles-Making-A-Commercial',{'data':'Audio/Orson+Welles+trying+to+make+a+commercial.mp3','display':'Orson Welles Making A Commercial'}],
    ['/audio/Philco-Radio-Time-Bing-Crosby-and-Brace-Beemer-01/07/1948',{'data':'Audio/01+Philco+Radio+Time+Bing+Crosby+and+Brace+Beemer.mp3','display':'Philco Radio Time - Bing Crosby and Brace Beemer - 01/07/1948'}],
    ['/audio/Phil-Gries-Interview-with-Fred-Foy-07/30/2010-and-08/07/2010',{'data':'Audio/Phil+Gries+Interview+Fred+Foy+2010.mp3','display':'Phil Gries Interview with Fred Foy - 07/30/2010 and 08/07/2010'}],
    ['/audio/President-Kennedy Assassination-Part One-ABC-Radio-News-11/22-11/25/1963',{'data':'Audio/President+Kennedy+Assassination+narrated+by+Fred+Foy+ABC+Radio+News+11_22-11_25_1963.mp3','display':'President Kennedy Assassination - Part One -  ABC Radio News - 11/22-11/25/1963'}],
    ['/audio/President-Kennedy-Assassination-Part Two-ABC-Radio-News-11/22-11/25/1963',{'data':'Audio/President+Kennedy+Assassination+(Part+Two)+narrated+by+Fred+Foy+ABC+Radio+News.mp3','display':'President Kennedy Assassination - Part Two - ABC Radio News - 11/22-11/25/1963'}],
    ['/audio/President-Kennedy-Assassination-Part Three-ABC-Radio-News-11/22-11/25/1963',{'data':'Audio/President+Kennedy+Assassination+(Part+Three)+narrated+by+Fred+Foy+ABC+Radio+News+11_22-11_25_1963.mp3','display':'President Kennedy Assassination - Part Three -  ABC Radio News - 11/22-11/25/1963'}],
    ['/audio/President-Kennedy-Assassination-Concluding-ABC-Radio-News-11/25/1963',{'data':'Audio/JFK+Assassination+Coverage+(concluding+segment)+narrator+Fred+Foy+ABC+Radio+News+11_25_1963.mp3','display':'President Kennedy Assassination - Concluding - ABC Radio News - 11/25/1963'}],
    ['/audio/Radio-Hall-of-Fame-Chicago-2000',{'data':'Audio/Radio+Hall+of+Fame+-+Chicago.mp3','display':'Radio Hall of Fame - Chicago - 2000'}],
    ['/audio/Radio-Historical-Association-of-Colorado-Part-One-1987',{'data':'Audio/RHAC+1987.mp3','display':'Radio Historical Association of Colorado - Part One - 1987'}],
    ['/audio/Radio-Historical-Association-of-Colorado-Part-Two-1987',{'data':'Audio/RHAC2.mp3','display':'Radio Historical Association of Colorado - Part Two - 1987'}],
    ['/audio/Sergeant-Preston-of-the-Yukon-2 Episodes',{'data':'Audio/Dad+as+Sergeant+Preston+1954.mp3','display':'Sergeant Preston of the Yukon - 2 Episodes'}],
    ['/audio/Sergeant-Preston-Land-Deal-01/27/1955',{'data':'Audio/01+Sergeant+Preston+-+Land+Deal+01_27_1955.mp3','display':'Sergeant Preston - Land Deal - 01/27/1955'}],
    ['/audio/Sergeant-Preston-Race-Against-Time-02/08/1955',{'data':'Audio/02+Sergeant+Preston+-+Race+Against+Time+02_08_1955.mp3','display':'Sergeant Preston - Race Against Time - 02/08/1955'}],
    ['/audio/Sounds-of-Yesterday-My-Attempt-at-a-Bit-of-Nostalgia',{'data':'Audio/Sounds+of+Yesterday+-+My+Attempt+at+a+Bit+of+Nostalgia.mp3','display':'Sounds of Yesterday - My Attempt at a Bit of Nostalgia'}],
    ['/audio/Special-VJ-Day-Show-09/02/1945',{'data':'Audio/Special+VJ+Day+Show+Sept_2_1945.mp3','display':'Special VJ Day Show - 09/02/1945'}],
    ['/audio/Theatre-Five',{'data':'Audio/Theatre+Five.mp3','display':'Theatre Five'}],
    ['/audio/Theatre-Five-Compilation',{'data':'Audio/Theatre+Five+Compilation.mp3','display':'Theatre Five Compilation'}],
    ['/audio/The-Best-of-Chuck-Schaden-Part-One',{'data':'Audio/The+Best+of+Chuck+Schadens+Those+Were+The+Days+25th+Anniversary+Show+Part+One.mp3','display':'The Best of Chuck Schaden - Part One'}],
    ['/audio/The-Best-of-Chuck-Schaden-Part-Two',{'data':'Audio/The+Best+of+Chuck+Schadens+Those+Were+The+Days+25th+Anniversary+Show+Part+Two.mp3','display':'The Best of Chuck Schaden - Part Two'}],
    ['/audio/The-Eagle-and-The-Bear-Introduction-The-Allies-09/21/1966',{'data':'Audio/The+Eagle+and+The+Bear+-+1+Introduction+-+The+Allies+-+narrator+Fred+Foy+-+09_21_1966.mp3','display':'The Eagle and The Bear - Introduction - The Allies - 09/21/1966'}],
    ['/audio/The-Eagle-and-The-Bear-Potsdam-09/28/19-66',{'data':'Audio/The+Eagle+and+The+Bear+-+2+Potsdam+-+narrator+Fred+Foy+-+09_28_1966.mp3','display':'The Eagle and The Bear - Potsdam - 09/28/1966'}],
    ['/audio/The-Eagle-and-The-Bear-Emphasis-Europe-0/05/1966',{'data':'Audio/The+Eagle+and+The+Bear+-+3+Emphasis+Europe+-+narrator+Fred+Foy+-+10_05_1966.mp3','display':'The Eagle and The Bear - Emphasis Europe - 10/05/1966'}],
    ['/audio/The-Eagle-and-The-Bear-Eagles-in-the-Corridor-10/12/1966',{'data':'Audio/The+Eagle+and+The+Bear+-+4+Eagles+in+the+Corridor+-+narrator+Fred+Foy+-+10_12_1966.mp3','display':'The Eagle and The Bear - Eagles in the Corridor - 10/12/1966'}],
    ['/audio/The-Eagle-and-The-Bear-The-Red-Menace-10/19/1966',{'data':'Audio/The+Eagle+and+The+Bear+-+5+The+Red+Menace+-+narrator+Fred+Foy+-+10_19_1966.mp3','display':'The Eagle and The Bear - The Red Menace  - 10/19/1966'}],
    ['/audio/The-Eagle-and-The-Bear-Korea-The-Decision-to-Fight-10/26/1966',{'data':'Audio/The+Eagle+and+The+Bear+-+6+Korea+-+The+Decision+to+Fight+-+narrator+Fred+Foy+-+10_26_1966.mp3','display':'The Eagle and The Bear - Korea - The Decision to Fight - 10/26/1966'}],
    ['/audio/The-Eagle-and-The-Bear-Korea-The-Decision-to-Quit-11/02/1966',{'data':'Audio/The+Eagle+and+The+Bear+-+7+Korea+-+The+Decision+to+Quit+-+narrator+Fred+Foy+-+11_02_1966.mp3','display':'The Eagle and The Bear - Korea - The Decision to Quit - 11/02/1966'}],
    ['/audio/The-Eagle-and-The-Bear-The-Age-of-Coexistence-11/09/1966',{'data':'Audio/The+Eagle+and+The+Bear+-+8+The+Age+of+Coexistence+-+narrator+Fred+Foy+-+11_09_1966.mp3','display':'The Eagle and The Bear - The Age of Coexistence - 11/09/1966'}],
    ['/audio/The-Eagle-and-The-Bear-Over-Flight-and-Over-Kill-11/16/1966',{'data':'Audio/The+Eagle+and+The+Bear+-+9+Over+Flight+and+Over+Kill+-+narrator+Fred+Foy+-+11_16_1966.mp3','display':'The Eagle and The Bear - Over Flight and Over Kill - 11/16/1966'}],
    ['/audio/The-Eagle-and-The-Bear-The-Uncommitted-11/23/1966',{'data':'Audio/The+Eagle+and+The+Bear+-+10+The+Uncommitted+-+narrator+Fred+Foy+-+11_23_1966.mp3','display':'The Eagle and The Bear - The Uncommitted - 11/23/1966'}],
    ['/audio/The-Eagle-and-The-Bear-Two-Weeks-in-October-11/30/1966',{'data':'Audio/The+Eagle+and+The+Bear+-+11+Two+Weeks+in+October+-+narrator+Fred+Foy+-+11_30_1966.mp3','display':'The Eagle and The Bear - Two Weeks in October - 11/30/1966'}],
    ['/audio/The-Eagle-and-The-Bear-New-Faces-of-1964-12/07/1966',{'data':'Audio/The+Eagle+and+The+Bear+-+12+New+Faces+of+1964+-+narrator+Fred+Foy+-+12_07_1966.mp3','display':'The Eagle and The Bear - New Faces of 1964 - 12/07/1966'}],
    ['/audio/The-Eagle-and-The-Bear-The Eagle, The Bear and The Dragon-12/14/1966',{'data':'Audio/The+Eagle+and+The+Bear+-+13+The+Eagle+The+Bear+and+The+Dragon+-+written+by+H+Paul+Jeffers+-+narrator+Fred+Foy+-+12_14_1966.mp3','display':'The Eagle and The Bear - The Eagle, The Bear and The Dragon - 12/14/1966'}],
    ['/audio/The-Fear-That-Creeps-Like-A-Cat-Part-One',{'data':'Audio/The+Fear+That+Creeps+Like+A+Cat.mp3','display':'The Fear That Creeps Like A Cat - Part One'}],
    ['/audio/The-Fear-That-Creeps-Like-A-Cat-Part-Two',{'data':'Audio/The+Fear+That+Creeps+Like+A+Cat2.mp3','display':'The Fear That Creeps Like A Cat - Part Two'}],
    ['/audio/The-Fear-That-Creeps-Like-A-Cat-Part-Three',{'data':'Audio/The+Fear+That+Creeps+Like+A+Cat3.mp3','display':'The Fear That Creeps Like A Cat - Part Three'}],
    ['/audio/The-Green-Hornet-Graft-Crosses-A-Bridge-11/04/1947',{'data':'Audio/01+The+Green+Hornet.mp3','display':'The Green Hornet - Graft Crosses A Bridge - 11/04/1947'}],
    ['/audio/The-Green-Hornet-Too-Hot-To-Handle-11/11/1947',{'data':'Audio/02+The+Green+Hornet.mp3','display':'The Green Hornet - Too Hot To Handle - 11/11/1947'}],
    ['/audio/The-Live-Show-Radio-Once-More-Part-One',{'data':'Audio/The+Live+Show+-+Radio+Once+More+(Part+One)+-+Fred+Foy+-+Terry+Salomonson.mp3','display':'The Live Show - Radio Once More - Part One'}],
    ['/audio/The-Live-Show-Radio-Once-More-Part-Two',{'data':'Audio/The+Live+Show+-+Radio+Once+More+(Part+Two)+-+Fred+Foy.mp3','display':'The Live Show - Radio Once More - Part Two'}],
    ['/audio/The-Live-Show-Radio-Once-More-Part-Three',{'data':'Audio/The+Live+Show+-+Radio+Once+More+(Part+Three)+-+Fred+Foy.mp3','display':'The Live Show - Radio Once More - Part Three'}],
    ['/audio/The-Lone-Ranger-15th-Anniversary-Show-Abilene-Horse-Thieves',{'data':'Audio/Abilene+Horse+Thieves.mp3','display':'The Lone Ranger 15th Anniversary Show - Abilene Horse Thieves'}],
    ['/audio/The-Lone-Ranger-20th-Anniversary-Show-01/30/1953',{'data':'Audio/20th+Anniversary+Lone+Ranger+Show.mp3','display':'The Lone Ranger 20th Anniversary Show - 01/30/1953'}],
    ['/audio/The-Lone-Ranger-The-Origin-of-the-Lone-Ranger',{'data':'Audio/The+Lone+Ranger+-+The+Origin+of+the+Lone+Ranger+-+Fred+Foy.mp3','display':'The Lone Ranger - The Origin of the Lone Ranger'}],
    ['/audio/The-Lone-Ranger-Christmas-Show-Three-Wise-Men',{'data':'Audio/Lone+Ranger+Christmas+Show+Three+Wise+Men+Side+One.mp3','display':'The Lone Ranger Christmas Show - Three Wise Men'}],
    ['/audio/The-Lone-Ranger-Green-Cross-Safety-Spots-Part-One',{'data':'Audio/The+Lone+Ranger+-+Green+Cross+Safety+Spots.mp3','display':'The Lone Ranger - Green Cross Safety Spots - Part One'}],
    ['/audio/The-Lone-Ranger-Green-Cross-Safety-Spots-Part-Two',{'data':'Audio/The+Lone+Ranger+-+Green+Cross+Safety+Spots2.mp3','display':'The Lone Ranger - Green Cross Safety Spots - Part Two'}],
    ['/audio/The-Lone-Ranger-Green-Cross-Safety-Spots-Part-Three',{'data':'Audio/The+Lone+Ranger+-+Green+Cross+Safety+Spots3.mp3','display':'The Lone Ranger - Green Cross Safety Spots - Part Three'}],
    ['/audio/The-Lone-Ranger-Return-of-Cavendish-01/30/1953',{'data':'Audio/RETURN+OF+CAVENDISH+-+JAN-30-1953.mp3','display':'The Lone Ranger - Return of Cavendish - 01/30/1953'}],
    ['/audio/The-Lone-Ranger-The-Adventure-of-the-Bluefield-Prison-Break',{'data':'Audio/The+Lone+Ranger+-+The+Adventure+of+the+Bluefield+Prison+Break+-+Fred+Foy.mp3','display':'The Lone Ranger - The Adventure of the Bluefield Prison Break'}],
    ['/audio/The-Lone-Ranger-The-Adventure-of-the-Lone-Ranger-and-The-War-Horse',{'data':'Audio/The+Lone+Ranger+-+The+Adventure+of+the+Lone+Ranger+and+The+War+Horse+-+Fred+Foy.mp3','display':'The Lone Ranger - The Adventure of the Lone Ranger and The War Horse'}],
    ['/audio/The-Lone-Ranger-The-Legend-of-the-Lone-Ranger-and-the-Colonels-Son',{'data':'Audio/The+Lone+Ranger+-+The+Legend+of+the+Lone+Ranger+and+the+Colonels+Son+-+Fred+Foy.mp3','display':"The Lone Ranger - The Legend of the Lone Ranger and the Colonel's Son"}],
    ['/audio/The-Lone-Ranger-The-Legend-of-Dan-Reid',{'data':'Audio/The+Lone+Ranger+-+The+Legend+of+Dan+Reid+-+The+story+of+how+the+Lone+Ranger+found+his+only+living+relative+-+Fred+Foy.mp3','display':'The Lone Ranger - The Legend of Dan Reid'}],
    ['/audio/The-Lone-Ranger-The-Story-of-How-Tonto-Saved-The-Lone-Ranger',{'data':'Audio/The+Lone+Ranger+-+The+Story+of+How+Tonto+Saved+The+Lone+Ranger+-+Fred+Foy.mp3','display':'The Lone Ranger - The Story of How Tonto Saved The Lone Ranger'}],
    ['/audio/The-Lone-Ranger-The-Lone-Ranger-and-the-Booneville-Gold',{'data':'Audio/The+Lone+Ranger+-+The+Lone+Ranger+and+the+Booneville+Gold+-+Fred+Foy.mp3','display':'The Lone Ranger - The Lone Ranger and the Booneville Gold'}],
    ['/audio/The-Lone-Ranger-The-Story-of-the-Lone-Ranger-and-his-Great-Horse-Silver',{'data':'Audio/The+Lone+Ranger+-+The+Story+of+the+Lone+Ranger+and+his+Great+Horse+Silver+-+Fred+Foy.mp3','display':'The Lone Ranger - The Story of the Lone Ranger and his Great Horse Silver'}],
    ['/audio/The-Lone-Ranger-The-Origin-of-the-Lone-Ranger',{'data':'Audio/The+Lone+Ranger+-+The+Origin+of+the+Lone+Ranger+-+Fred+Foy.mp3','display':'The Lone Ranger - The Origin of the Lone Ranger'}],
    ['/audio/The-Lone-Ranger-Those-Thrilling-Days-of-Yesterday',{'data':'Audio/The+Lone+Ranger+-+Those+Thrilling+Days+of+Yesterday.mp3','display':'The Lone Ranger - Those Thrilling Days of Yesterday'}],
    ['/audio/The-Lone-Ranger-Music-and-Miscellaneous-Items-and-Comedy-Bits-Part-One',{'data':'Audio/The+Lone+Ranger+-+Music+and+Miscellaneous+Items+and+Comedy+Bits.mp3','display':'The Lone Ranger - Music and Miscellaneous Items and Comedy Bits - Part One'}],
    ['/audio/The-Lone-Ranger-Music-and-Miscellaneous-Items-and-Comedy-Bits-Part-Two',{'data':'Audio/The+Lone+Ranger+-+Music+and+Miscellaneous+Items+and+Comedy+Bits2.mp3','display':'The Lone Ranger - Music and Miscellaneous Items and Comedy Bits - Part Two'}],
    ['/audio/The-Lone-Ranger-15th-Anniversary-Show-The-Origin-of-The-Lone Ranger',{'data':'Audio/The+Lone+Ranger+15th+Anniversary+Show+The+Origins+Of+The+Lone+Ranger.mp3','display':'The Lone Ranger 15th Anniversary Show - The Origin of The Lone Ranger'}],
    ['/audio/The-Lone-Ranger-Jim-Millers-Return-Fred-Foy-cast-as-Jim-Miller',{'data':'Audio/The+Lone+Ranger+Jim+Millers+Return+11_14_1946+Fred+Foy+cast+as+Jim+Miller.mp3','display':"The Lone Ranger  - Jim Miller's Return - Fred Foy cast as Jim Miller"}],
    ['/audio/The-Lone-Ranger-Silver-Spurs-05/21/1948',{'data':'Audio/Lone+Ranger+Silver+Spurs+05-21-1948.mp3','display':'The Lone Ranger - Silver Spurs - 05/21/1948'}],
    ['/audio/The-Lone-Ranger-Stranger-From-The-East',{'data':'Audio/The+Lone+Ranger+-+Stranger+From+The+East+-+Fred+Foy.mp3','display':'The Lone Ranger - Stranger From The East'}],
    ['/audio/The-Lone-Ranger-Panchos-Double-07/30/1948',{'data':'Audio/Lone+Ranger+Panchos+Double+07-30-1948.mp3','display':'The Lone Ranger - Panchos Double - 07/30/1948'}],
    ['/audio/The-Lone-Ranger-Race-to-the-Wire-05/25/1955',{'data':'Audio/Race+to+the+Wire3.mp3','display':'The Lone Ranger - Race to the Wire - 05/25/1955'}],
    ['/audio/This-is-ABC-Radio',{'data':'Audio/This+Is+ABC+Radio.mp3','display':'This is ABC Radio'}],
    ['/audio/Tribute-to-Brace-Fred-Foy-Oxford-MI-Memorial-Centennial-Park-01/18-01/24',{'data':'Audio/Tribute+to+Brace+-+Fred+Foy+-+Oxford+MI+-+Memorial+Centennial+Park+-+01_18-01_24.mp3','display':'Tribute to Brace - Fred Foy - Oxford MI - Memorial Centennial Park - 01/18-01/24'}],
    ['/audio/Tribute-to-Brace-Beemer-J.P.-McCarthy',{'data':'Audio/Tribute+to+Brace+Beemer.mp3','display':'Tribute to Brace Beemer - J.P. McCarthy'}],
    ['/audio/Tribute-to-Fred-Foy-The-Steve-LeVeille-Show-12/23/10',{'data':'Audio/Tribute+to+Fred+Foy+12_23_10+The+Steve+LeVeille+Show.mp3','display':'Tribute to Fred Foy - The Steve LeVeille Show - 12/23/10'}],
    ['/audio/The-Story-of-the-Pilgrims-Part-One',{'data':'Audio/The+Story+of+the+Pilgrims+side+01.mp3','display':'The Story of the Pilgrims - Part One'}],
    ['/audio/The-Story-of-the-Pilgrims-Part-Two',{'data':'Audio/The+Story+of+the+Pilgrims+side+02.mp3','display':'The Story of the Pilgrims - Part Two'}],
    ['/audio/Voices-In-The-Headlines-Watts-Riot-08/15/1965',{'data':'Audio/Voices+In+The+Headlines+Watts+Riot+Fred+Foy+08151965.mp3','display':'Voices In The Headlines - Watts Riot - 08/15/1965'}],
    ['/audio/Voices-In-The-Headlines-The-Lone-Ranger-Ends-Broadcasting-02/02/1964',{'data':'Audio/Voices+in+the+Headlines+-+Fred+Foy+-+02_02_1964+-+The+Lone+Ranger+Ends+Broadcasting+On+ABC+Radio.mp3','display':'Voices In The Headlines - The Lone Ranger Ends Broadcasting - 02/02/1964'}],
    ['/audio/WCCM-Radio-800-AM-Thanks-For-The-Memories-11/09/1987',{'data':'Audio/WCCM+Fred+Foy+11091987.mp3','display':'WCCM Radio 800 AM - Thanks For The Memories - 11/09/1987'}],
    ['/audio/WNYC-Fred-Foy-Interview-10/18/2005',{'data':'Audio/01+WNYC+Fred+Foy+Interview.mp3','display':'WNYC - Fred Foy Interview - 10/18/2005'}],
    ['/audio/WOCM-Fred-Foy-Interview-10/19/2005',{'data':'Audio/02+WOCM+Fred+Foy+Interview.mp3','display':'WOCM - Fred Foy Interview - 10/19/2005'}],
    ['/audio/When-Radio-Was-WMAQ-Carl-Amari',{'data':'Audio/When+Radio+Was.mp3','display':'When Radio Was - WMAQ - Carl Amari'}],
    ['/audio/William-Tell-Overture-and-Origin',{'data':'Audio/William+Tell+Overture+and+Origin.mp3','display':'William Tell Overture and Origin'}],
    ['/audio/William-Tell-Overture-Origin-Scene-Dads-Tape-4-min',{'data':'Audio/William+Tell+Overture+Origin+Scene+Dads+Tape+4+min.mp3','display':"William Tell Overture Origin Scene Dad's Tape 4 min"}],
    ['/audio/Dads-Christmas-Card-to-Nancy,-Wendy-and-Fritz-1982',{'data':'Audio/Dads+Christmas+Card+1982.mp3','display':"Dad's Christmas Card to Nancy, Wendy and Fritz - 1982"}],
    ['/audio/Dad,-Nancy,-Wendy-and Fritz-1962',{'data':'Audio/Dad+Nancy+Wendy+and+Fritz+1962.mp3','display':'Dad, Nancy, Wendy and Fritz - 1962'}],
    ['/audio/Easter-Bunny-Bunny',{'data':'Audio/Easter+Bunny+Bunny.mp3','display':'Easter Bunny Bunny'}],
    ['/audio/Justin-Christmas-1992-From-Pa',{'data':'Audio/To+Justin+Xmas+1992+From+Pa.mp3','display':'Justin Christmas 1992 From Pa'}],
    ['/audio/Justin-and-Pa-Recording-(Lone-Ranger-Recreation)',{'data':'Audio/Justin+and+Pa+Recording+(Lone+Ranger+Recreation).mp3','display':'Justin and Pa Recording (Lone Ranger Recreation)'}],
    ['/audio/Justin-At-The-Piano-(with-his-Pa)',{'data':'Audio/Justin+At+The+Piano+(with+his+Pa).mp3','display':'Justin At The Piano (with his Pa)'}],
    ['/audio/J-Von-Cutietta-(with-his-Pa)-(Lone-Ranger-Recreation)',{'data':'Audio/J+Von+Cutietta+(with+his+Pa)+(Lone+Ranger+Recreation).mp3','display':'J Von Cutietta (with his Pa) (Lone Ranger Recreation)'}],
    ['/audio/Nancy-Singing-Davey-Crockett',{'data':'Audio/Davey.mp3','display':'Nancy Singing Davey Crockett'}],
    ['/audio/Nancy-Singing-Someday-My-Prince-Will-Come',{'data':'Audio/Someday.mp3','display':'Nancy Singing Someday My Prince Will Come'}],
    ['/audio/Wendys-Repertoire',{'data':'Audio/Repertoire.mp3','display':"Wendy's Repertoire"}],
    ['/audio/Wendy-Singing-Frere-Jacques-and-Jingle-Bells-1958',{'data':'Audio/Frere+Jacques.mp3','display':'Wendy Singing Frere Jacques and Jingle Bells - 1958'}],
    ['/audio/The-Missouri-Waltz',{'data':'Audio/Missouri+Waltz.mp3','display':'The Missouri Waltz'}],
    ['/audio/Schnitzelbank-Song',{'data':'Audio/Schnitzelbank+track+07.mp3','display':'Schnitzelbank Song'}],
    ['/audio/Bizet-Patrie-Overture',{'data':'Audio/Bizet+Patrie+Overture.mp3','display':'Bizet Patrie Overture'}],
    ['/audio/Beethoven-Egmont-Overture-Excerpt-#1',{'data':'Audio/Beethoven+Egmont+Overture+-+Excerpt+1.mp3','display':'Beethoven Egmont Overture - Excerpt #1'}],
    ['/audio/Beethoven-Egmont-Overture-Excerpt-#2',{'data':'Audio/Beethoven+Egmont+Overture+-+Excerpt+2.mp3','display':'Beethoven Egmont Overture - Excerpt #2'}],
    ['/audio/Beethoven-Egmont-Overture-Excerpt-#3',{'data':'Audio/Beethoven+Egmont+Overture+-+Excerpt+3.mp3','display':'Beethoven Egmont Overture - Excerpt #3'}],
    ['/audio/Beethoven-Symphony-7-2nd-Movement',{'data':'Audio/Beethoven+Symphony+7+-+2nd+Movement.mp3','display':'Beethoven Symphony 7 - 2nd Movement'}],
    ['/audio/Berlioz-Roman-Carnival-Overture',{'data':'Audio/Berlioz+Roman+Carnival+Overture.mp3','display':'Berlioz Roman Carnival Overture'}],
    ['/audio/Borodin-Polovetsian-Dances-Band-Arrangement',{'data':'Audio/Borodin+Polovetsian+Dances+(Band+Arrangement).mp3','display':'Borodin Polovetsian Dances - Band Arrangement'}],
    ['/audio/Charles-Williams-Winged-Messenger',{'data':'Audio/Charles+Williams+Winged+Messenger+(The+Silver+Eagle+Opening+Theme).mp3','display':'Charles Williams Winged Messenger'}],
    ['/audio/Colombo-Bonnell-Heavy-Agitato-1',{'data':'Audio/Colombo+Bonnell+Heavy+Agitato+1.mp3','display':'Colombo Bonnell Heavy Agitato 1'}],
    ['/audio/Colombo-Bonnell-The-Getaway-Part-1',{'data':'Audio/Colombo+Bonnell+The+Getaway+-+Part+1.mp3','display':'Colombo Bonnell The Getaway - Part 1'}],
    ['/audio/Colombo-Bonnell-The-Lone-Ranger-Foreword',{'data':'Audio/Colombo+Bonnell+The+Lone+Ranger+Foreword.mp3','display':'Colombo Bonnell The Lone Ranger Foreword'}],
    ['/audio/Colombo-Bonnell-The-Lone-Ranger-Main-Title',{'data':'Audio/Colombo+Bonnell+The+Lone+Ranger+Main+Title.mp3','display':'Colombo Bonnell The Lone Ranger Main Title'}],
    ['/audio/Colombo-Lava-Bonnell-The-Fighting-Devil-Dogs-End-Title',{'data':'Audio/Colombo+Lava+Bonnell+The+Fighting+Devil+Dogs+End+Title.mp3','display':'Colombo Lava Bonnell The Fighting Devil Dogs End Title'}],
    ['/audio/Flotow-Stradella-Overture',{'data':'Audio/Flotow+Stradella+Overture.mp3','display':'Flotow Stradella Overture'}],
    ['/audio/Hajos-Bonnell-Mechanical-Montage',{'data':'Audio/Hajos+Bonnell+Mechanical+Montage.mp3','display':'Hajos Bonnell Mechanical Montage'}],
    ['/audio/Liszt-Mazeppa-Excerpt-#1',{'data':'Audio/Liszt+Mazeppa+-+Excerpt+1.mp3','display':'Liszt Mazeppa - Excerpt #1'}],
    ['/audio/Liszt-Mazeppa-Excerpt-#2',{'data':'Audio/Liszt+Mazeppa+-+Excerpt+2.mp3','display':'Liszt Mazeppa - Excerpt #2'}],
    ['/audio/Mendelssohn-Ruy-Blas-Overture-Excerpt-#1',{'data':'Audio/Mendelssohn+Ruy+Blas+Overture+-+Excerpt+1.mp3','display':'Mendelssohn Ruy Blas Overture - Excerpt #1'}],
    ['/audio/Mendelssohn-Ruy-Blas-Overture-Excerpt-#2',{'data':'Audio/Mendelssohn+Ruy+Blas+Overture+-+Excerpt+2.mp3','display':'Mendelssohn Ruy Blas Overture - Excerpt #2'}],
    ['/audio/Mendelssohn-Symphony-3-Scotch-Scherzo-Excerpt-#1',{'data':'Audio/Mendelssohn+Symphony+3+Scotch+-+Scherzo+-+Excerpt+1.mp3','display':'Mendelssohn Symphony 3 Scotch - Scherzo - Excerpt #1'}],
    ['/audio/Mendelssohn-Symphony-3-Scotch-Scherzo-Excerpt-#2',{'data':'Audio/Mendelssohn+Symphony+3+Scotch+-+Scherzo+-+Excerpt+2.mp3','display':'Mendelssohn Symphony 3 Scotch - Scherzo - Excerpt #2'}],
    ['/audio/Reznicek-Donna-Diana-Overture-Excerpt-#1',{'data':'Audio/Reznicek+Donna+Diana+Overture+-+Excerpt+1+(Sgt.+Preston+Of+The+Yukon+Theme).mp3','display':'Reznicek Donna Diana Overture - Excerpt #1'}],
    ['/audio/Reznicek-Donna-Diana-Overture-Excerpt-#2',{'data':'Audio/Reznicek+Donna+Diana+Overture+-+Excerpt+2.mp3','display':'Reznicek Donna Diana Overture - Excerpt #2'}],
    ['/audio/Reznicek-Donna-Diana-Overture-Excerpt-#3',{'data':'Audio/Reznicek+Donna+Diana+Overture+-+Excerpt+3.mp3','display':'Reznicek Donna Diana Overture - Excerpt #3'}],
    ['/audio/Rimsky-Korsakov-The-Flight-Of-The-Bumble-Bee',{'data':'Audio/Rimsky-Korsakov+The+Flight+Of+The+Bumble+Bee+(Theme-+The+Green+Hornet).mp3','display':'Rimsky-Korsakov The Flight Of The Bumble Bee'}],
    ['/audio/Strauss-Johann-Jr-One-Night-In-Venice-Overture',{'data':'Audio/Strauss+Johann+Jr+One+Night+In+Venice+Overture.mp3','display':'Strauss Johann Jr One Night In Venice Overture'}],
    ['/audio/Suppe-Franz-Von-Wiener-Jubel-Overture',{'data':'Audio/Suppe+Franz+Von+Wiener+Jubel+Overture.mp3','display':'Suppe Franz Von Wiener Jubel Overture'}],
    ['/audio/Walter-Collins-The-Great-Assembly-March',{'data':'Audio/Walter+Collins+The+Great+Assembly+March+(The+Silver+Eagle+Closing+Theme).mp3','display':'Walter Collins The Great Assembly March'}],
    ['/audio/Wagner-Rienzi-Overture-Excerpt-#1',{'data':'Audio/Wagner+Rienzi+Overture+-+Excerpt+1.mp3','display':'Wagner Rienzi Overture - Excerpt #1'}],
    ['/audio/Wagner-Rienzi-Overture-Excerpt-#2',{'data':'Audio/Wagner+Rienzi+Overture+-+Excerpt+2.mp3','display':'Wagner Rienzi Overture - Excerpt #2'}],
    ['/audio/Wagner-Rienzi-Overture-Excerpt-#3',{'data':'Audio/Wagner+Rienzi+Overture+-+Excerpt+3.mp3','display':'Wagner Rienzi Overture - Excerpt #3'}],
    ['/audio/Weber-Oberon-Overture',{'data':'Audio/Weber+Oberon+Overture.mp3','display':'Weber Oberon Overture'}],
    ['/audio/The-Wild-West-Show',{'data':'Audio/Wild_West_Show_Demo_2001__.mp3','display':'The Wild West Show - Montie Montana Jr. - Bob Burnham'}]
  ]
}