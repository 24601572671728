import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import descriptions from './descriptions.js'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

export default class Video extends Component {
	constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {s3Location: null};    
  }

  componentDidMount() {
    const locationIsNotSet = this.state.s3Location ? false : true;
    if (!this.state.s3Location){
      this.buildMenuItems();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.s3Location) {
      this.setState({s3Location:null});
    }
  }

  handleClick = (e) => {
    e.preventDefault();
    this.doNavigation(e.target.attributes.getNamedItem('data').value);
    const href = e.target.attributes.getNamedItem('href').value;
    history.push(href);
  };

  doNavigation = (location) => {
    this.setState({s3Location:location});
  }

  buildMenuItems = () => {
    var ul = document.createElement('ul');
    //divide map by 2 to determine left and right
    var splitMenu = this.videoMap.length / 2;
    var ulItem = document.getElementById('fredMenul').appendChild(ul);
    let bSplitMenu = false;
    this.videoMap.forEach((element, index) => {
      
      if(index >= splitMenu && !bSplitMenu){
        var ul = document.createElement('ul');
        ulItem = document.getElementById('fredMenur').appendChild(ul);
        bSplitMenu = true;
      }

      let li = document.createElement('li');
      let a = document.createElement("a");
      a.onclick = this.handleClick;
      a.href = element[0];
      a.innerHTML = element[1].display;
      a.setAttribute('data',element[1].data);
      li.append(a);
      ulItem.appendChild(li);
      let hr = document.createElement('hr')
      ulItem.appendChild(hr);
    })
  }

	render() {
    const isLocationSet = this.state.s3Location ? true : false;
    let showMenu = ''
    let showVideo = 'hideVideoDisplay'
    let videoFile = '';
    let description = '';
    if (isLocationSet) {
      const location = this.state.s3Location;
      videoFile = '//s3.amazonaws.com/fredfoy.com/' + location;
      const descriptionKey = location.split('/')[1];
      description = descriptions[descriptionKey].info; 
      showMenu = 'hideMenuDisplay'
      showVideo = ''
    }
    return (
      <div>
        <div className={showVideo}>
          <div style={{'display':'flex', 'alignItems': 'center', 'justifyContent': 'center','marginTop':'48px','marginRight':'-10px'}} >
            <ReactPlayer url={videoFile} controls/>
          </div>
          <div style={{'textAlign':'center'}}>{description}</div>
        </div>
          <div className={showMenu}>
            <div id='fredMenu1'>
              <div id='fredMenul' className='menu1l menux'></div>
              <div id='fredMenur' className='menu1r menux'></div>
          </div>
        </div>
      </div>
    )
  }

  videoMap = [
    ['/video/Andy-Southards-Phone-Message',{'data':'Video/Andy+Phone+Message.mp4','display':"Andy Southard's Phone Message"}],
    ['/video/100-Paintings',{'data':'Video/100+Paintings.mp4','display':'100 Paintings'}],
    ['/video/Chicago-Radio-Hall-of-Fame-Induction-Ceremony-1995',{'data':'Video/Chicago+Radio+Hall+of+Fame+Induction+Ceremony+1995.mp4','display':'Chicago Radio Hall of Fame Induction Ceremony - 1995'}],
    ['/video/Dad-Amagansett-Door',{'data':'Video/Dad+Amagansett+Door.mp4','display':'Dad Amagansett Door'}],
    ['/video/Dad-and-Betty',{'data':'Video/Dad+and+Betty.mp4','display':'Dad and Betty'}],
    ['/video/Dick-Cavett-Commercials-1973',{'data':'Video/Dick+Cavett+Commercials+1973.mp4','display':'Dick Cavett Commercials - 1973'}],
    ['/video/Eyewitness-News-Farewell-12/13/1985',{'data':'Video/Eyewitness+News+Farewell+12_13_1985.mp4','display':'Eyewitness News Farewell - 12/13/1985'}],
    ['/video/FOTR-1993-Award-Fred-Foy',{'data':'Video/Fred+Foy+FOTR+1993+Award.mp4','display':'FOTR 1993 Award - Fred Foy'}],
    ['/video/FOTR-30th-Anniversary-Highlights-1976-2004-Part-One',{'data':'Video/FOTR+30th+Anniversary+Highlights+1976-2004+(Part+One).mp4','display':'FOTR 30th Anniversary Highlights 1976-2004 - Part One'}],
    ['/video/FOTR-30th-Anniversary-Highlights-1976-2004-Part-Two',{'data':'Video/FOTR+30th+Anniversary+Highlights+1976+-+2004+(Part+Two).mp4','display':'FOTR 30th Anniversary Highlights 1976 - 2004 - Part Two'}],
    ['/video/FOTR-Gift',{'data':'Video/FOTR+Gift.mp4','display':'FOTR Gift'}],
    ['/video/FOTR-Fred-Foy-and-Anthony-Tolin',{'data':'Video/FOTR+Fred+Foy+and+Anthony+Tolin.mp4','display':'FOTR Fred Foy and Anthony Tolin'}],
    ['/video/FOTR-Terry-Salomonson-Interview',{'data':'Video/Terry+Interview+FOTR.mp4','display':'FOTR Terry Salomonson Interview'}],
    ['/video/Freds-Scrapbook',{'data':'Video/Freds+Scrapbook.mp4','display':"Fred's Scrapbook"}],
    ['/video/Fred-Foy-Recording-Radio-Spirits-When-Radio-Was-1995',{'data':'Video/Fred+Foy+Recording+Radio+Spirits+When+Radio+Was+1995.mp4','display':'Fred Foy Recording Radio Spirits When Radio Was - 1995'}],
    ['/video/Ghost-of-the-Lone-Ranger-Featuring-Fred-Foy-2015',{'data':'Video/Ghost+of+the+Lone+Ranger.mp4','display':'Ghost of the Lone Ranger featuring Fred Foy - 2015'}],
    ['/video/Golden-Boot-Awards-Part-One-2004',{'data':'Video/Golden+Boot+Tape+1.mp4','display':'Golden Boot Awards - Part One - 2004'}],
    ['/video/Golden-Boot-Awards-Part-Two-2004',{'data':'Video/Golden+Boot+Tape+2.mp4','display':'Golden Boot Awards - Part Two - 2004'}],
    ['/video/Golden-Boot-Awards-Dad-Accepting-2004',{'data':'Video/Dad+Accepting+Golden+Boot.mp4','display':'Golden Boot Awards - Dad Accepting - 2004'}],
    ['/video/Golden-Boot-Awards-Excerpt-Home-Video-2004',{'data':'Video/Family+Reagan+Library.mp4','display':'Golden Boot Awards - Excerpt Home Video - 2004'}],
    ['/video/Guy-Lombardos-New-Years-Eve-1967',{'data':'Video/Guy+Lombardos+New+Years+Eve.mp4','display':"Guy Lombardo's New Years Eve - 1967"}],
    ['/video/Game-Shows',{'data':'Video/Game+Shows.mp4','display':'Game Shows'}],
    ['/video/Games',{'data':'Video/Games.mp4','display':'Games'}],
    ['/video/1960-Chevrolet-Corvair-Commercial',{'data':'Video/1960+Chevrolet+Corvair+Commercial.mp4','display':'Chevrolet Corvair Commercial - 1960'}],
    ['/video/Hollywood Bowl-William-Tell-Overture-08/12/2000',{'data':'Video/Fred+Foy+Hollywood+Bowl+August+2000.mp4','display':'Hollywood Bowl - William Tell Overture - 08/12/2000'}],
    ['/video/Hollywood-Close-Up',{'data':'Video/Hollywood+Close-Up.mp4','display':'Hollywood Close-Up'}],
    ['/video/Jay-Silverheels',{'data':'Video/Jay+Silverheels.mp4','display':'Jay Silverheels'}],
    ['/video/Kentucky-Broadcasters-Association-1991',{'data':'Video/Kentucky+Broadcasters+Association+-+1991.mp4','display':'Kentucky Broadcasters Association - 1991'}],
    ['/video/Lone-Ranger-Day-Brace-Beemer-Memorial-Park-Mt.-Carmel-2004',{'data':'Video/Lone+Ranger+Day+-+Brace+Beemer+Memorial+Park+-+Mt.+Carmel+-+2004.mp4','display':'Lone Ranger Day - Brace Beemer Memorial Park - Mt. Carmel - 2004'}],
    ['/video/Lone-Pine-1993',{'data':'Video/Lone+Pine+1993.mp4','display':'Lone Pine 1993'}],
    ['/video/Mom-and-Dad-1950',{'data':'Video/Mom+and+Dad+1950.mp4','display':'Mom and Dad - 1950'}],
    ['/video/NBHF-1977-First-Annual-Freehold,NJ',{'data':'Video/First+NBHF+1977+Freehold,+NJ.mp4','display':'NBHF 1977 First Annual Freehold, NJ'}],
    ['/video/NBHF-1978-Second-Annual-Freehold,NJ - Hall of Fame',{'data':'Video/Second+NBHF+1978+Freehold,+NJ+Hall+of+Fame.mp4','display':'NBHF 1978 Second Annual Freehold, NJ - Hall of Fame'}],
    ['/video/NBHF-Inductions-1993',{'data':'Video/NBHF+Inductions+1993+-+Dad+accepts+award.mp4','display':'NBHF Inductions - 1993'}],
    ['/video/NLAS-Convention-1999',{'data':'Video/NLAS+Convention+1999.mp4','display':'NLAS Convention - 1999'}],
    ['/video/OTR-Convention-Cincinnati-1996-Part-One',{'data':'Video/OTR+Convention+Cincinnati+1996+(Part+1).mp4','display':'OTR Convention Cincinnati - 1996 - Part One'}],
    ['/video/OTR-Convention-Cincinnati-1996-Part-Two',{'data':'Video/OTR+Convention+Cincinnati+1996+(Part+2).mp4','display':'OTR Convention Cincinnati - 1996 - Part Two'}],
    ['/video/Radio-Classics-Live-VI-1995-Complete',{'data':'Video/Radio+Classics+Live+VI+1995.mp4','display':'Radio Classics Live VI 1995 Complete'}],
    ['/video/Radio-Classics-Live-VI-1995-Excerpt',{'data':'Video/Radio+Classics+Live+VI+1995+Trimmed.mp4','display':'Radio Classics Live VI 1995 Excerpt'}],
    ['/video/Radio-Classics-Live-VII-Complete-03/29/1996',{'data':'Video/Radio+Classics+Live+VII+Complete+03_29_1996.mp4','display':'Radio Classics Live VII Complete - 03/29/1996'}],
    ['/video/Radio-Classics-Live-VII-Justin-as-Dan-Reid-1996',{'data':'Video/Radio+Classics+Live+VII+1996.mp4','display':'Radio Classics Live VII Justin as Dan Reid - 1996'}],
    ['/video/Radio-Classics-Live-VIII-1997',{'data':'Video/Radio+Classics+Live+VIII+1997.mp4','display':'Radio Classics Live VIII - 1997'}],
    ['/video/Radio-Classics-Live-IX-Fred-Wendy-and-Justin',{'data':'Video/Radio+Classics+Live+IX+Dad+Wendy+and+Justin.mp4','display':'Radio Classics Live IX Fred, Wendy and Justin'}],
    ['/video/Radio-Classics-Live-X-Complete-05/07/1999',{'data':'Video/Radio+Classics+Live+X+Complete+05_07_1999.mp4','display':'Radio Classics Live X Complete - 05/07/1999'}],
    ['/video/Radio-Classics-Live-XI-Complete-05/05/2000',{'data':'Video/Radio+Classics+Live+XI+Complete+05_05_2000.mp4','display':'Radio Classics Live XI Complete - 05/05/2000'}],
    ['/video/Radio-Classics-Live-XI-Excerpt-from-Home-Movie-05/05/2000',{'data':'Video/Radio+Classics+Live+XI+Excerpt+from+Home+Movie+05_05_2000.mp4','display':'Radio Classics Live XI Excerpt from Home Movie - 05/05/2000'}],
    ['/video/Radio-Classics-Live-XV-Home-Movie-2004',{'data':'Video/Radio+Classics+Live+XV+Home+Movie+2004.mp4','display':'Radio Classics Live XV Home Movie - 2004'}],
    ['/video/Radio-Classics-Live-XV-Excerpt-2004',{'data':'Video/Radio+Classics+Live+XV+Excerpt+2004.mp4','display':'Radio Classics Live XV Excerpt - 2004'}],
    ['/video/Remember-When',{'data':'Video/Remember+When+Trimmed.mp4','display':'Remember When'}],
    ['/video/Remembering-Radio-Dave-Parker',{'data':'Video/Remembering+Radio+-+Dave+Parker.mp4','display':'Remembering Radio - Dave Parker'}],
    ['/video/SPERDVAC-2008',{'data':'Video/SPERDVAC+2008.mp4','display':'SPERDVAC - 2008'}],
    ['/video/SPERDVAC-2008-The-Lone-Ranger-Panel',{'data':'Video/SPERDVAC+2008+PANEL3.mp4','display':'SPERDVAC - 2008 - The Lone Ranger Panel'}],
    ['/video/Seasonal-Farms-Turkey-Justin-and-Pa',{'data':'Video/Seasonal+Turkey+Justin+and+Pa.mp4','display':'Seasonal Farms Turkey - Justin and Pa'}],
    ['/video/Swinging-in-Birmingham-MI-1955',{'data':'Video/Swinging+Birmingham+MI+1955.mp4','display':'Swinging in Birmingham, MI. - 1955'}],
    ['/video/Sunday-Morning-Milepost',{'data':'Video/Sunday+Morning+Milepost.mp4','display':'Sunday Morning Milepost'}],
    ['/video/The-Lone-Ranger-Creed-2000',{'data':'Video/Fred+Foy+The+Lone+Ranger+Creed.mp4','display':'The Lone Ranger Creed - 2000'}],
    ['/video/The-Making-of-Doctor-Zhivago',{'data':'Video/The+Making+of+Doctor+Zhivago.mp4','display':'The Making of Doctor Zhivago'}],
    ['/video/The-Voice-Behind-the-Lone-Ranger-Bruce-Glazer-1994',{'data':'Video/The+Voice+Behind+the+Lone+Ranger+-+Bruce+Glazer+1994.mp4','display':'The Voice Behind the Lone Ranger - Bruce Glazer - 1994'}],
    ['/video/The-Legend-of-the-Lone-Ranger-Trailer',{'data':'Video/Legend+Lone+Ranger+Trailer.mp4','display':'The Legend of the Lone Ranger Trailer'}],
    ['/video/Today-Show-Willard-Scott-1995',{'data':'Video/Today+Show+Willard+Scott+1995.mp4','display':'Today Show Willard Scott - 1995'}],
    ['/video/WABC-AM-New-York-1979',{'data':'Video/WABC+AM+New+York.mp4','display':'WABC AM New York - 1979'}],
    ['/video/Wendy-Nancy-Fritz-and-Dad-Making-Entrance-Amagansett',{'data':'Video/Wendy+Nancy+Fritz+and+Dad+Making+Entrance+Amagansett.mp4','display':'Wendy, Nancy, Fritz and Dad Making Entrance Amagansett'}],
    ['/video/Dads-Memorial-Service-Part-One-2/19/2011',{'data':'Video/Dads+Memorial+Part+1.mp4','display':"Dad's Memorial Service - Part One - 2/19/2011"}],
    ['/video/Dads-Memorial-Service-Part-Two-2/19/2011',{'data':'Video/Dads+Memorial+Service+Part+2A.mp4','display':"Dad's Memorial Service - Part Two - 2/19/2011"}]
  ]
}