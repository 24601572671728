import React, { Component } from 'react';
import { BrowserRouter as Router, Link, Routes, Route } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Home from './home'
import AboutFredFoy from './aboutfredfoy'
import Audio from './audio'
import Video from './video'
import ReactGA from 'react-ga4';

const history = createHistory()
ReactGA.initialize("G-0F48N9CVFB");
history.listen((location, action) => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search});

});

function TabContainer(props) {
  return (
    <Typography component='div' style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
});


class FredFoyCom extends Component {

  state = {
    value: 0,
  };
  
  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    let MyContact = () => {
      document.location.href="mailto:goldenvoiceofradio@gmail.com?Subject=FredFoy.com"
    };

    return (
      <Router history={history}>
          <div className='site'>
            <header className='fredheader'>
              <div className='myheadercss'>
                <div className='FWF-logo' />
              </div>
              <div className={classes.root}>
                <AppBar style={{ background: '#648199'}}position='sticky' >
                  <Tabs value={value} onChange={this.handleChange}>
                    <Tab value={0} label='Home' to='/home' component={Link} />
                    <Tab value={1} label='Fred Foy' to='/aboutfredfoy' component={Link} />
                    <Tab value={2} label='Audio' to='/audio' component={Link} />
                    <Tab value={3} label='Video' to='/video' component={Link} />
                    <Tab value={4} label='Contact' to='/contact' component={Link} />
                  </Tabs>
                </AppBar>
                <Routes>
                  <Route exact path= "/" element={<Home/>} />
                  <Route path='/home' element={<Home/>} />
                  <Route path='/aboutfredfoy' element={<AboutFredFoy/>} />
                  <Route path='/audio' element={<Audio/>} />
                  <Route path='/video' element={<Video/>} />
                  <Route path='/contact' element={<MyContact />} />
                </Routes>
              </div>
            </header> 
          </div>
      </Router>
    )
  }
}

FredFoyCom.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FredFoyCom);
